@use 'style/index.module.scss' as *;

.wrapper {
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dotStep {
  position: absolute;
  top: -6px;
  border: solid 2px $off-white;
  border-radius: 12px;
  width: 8px;
  height: 8px;
}

.greyDot {
  background-color: $metal-bright-50;
}

.sandDot {
  background-color: $sand;
}

.clickableStep {
  cursor: pointer;
}

.line {
  height: 8px;
  border-radius: 4px;
}

.line.sandLine {
  background-color: $sand;
}

.line.greyLine {
  background-color: $metal-bright-50;
}

.iconAvatar {
  position: absolute;
  top: -18px;
  width: 34px;
  height: 34px;
  border: solid 2px $off-white;
  background-color: $midnight-black;
}

.icon {
  width: 24px;
  height: 24px;
}

.iconStepContainer,
.dotStepContainer {
  position: relative;
  display: flex;
  flex-direction: column;
}

.iconStepContainer.centerName,
.dotStepContainer.centerName {
  align-items: center;
}

.iconStepContainer.rightName,
.dotStepContainer.rightName {
  align-items: flex-end;

  @media only screen and (min-width: $md) {
    align-items: center;
  }
}

.iconStepContainer.leftName,
.dotStepContainer.leftName {
  @media only screen and (min-width: $md) {
    align-items: center;
  }
}

.stepName {
  @include Breadcrumb__Breadcrumb--Active--Desktop;
  position: absolute;
  width: max-content;
  top: 20px;
}

.inactiveStepName {
  display: none;

  @media only screen and (min-width: $sm) {
    display: unset;
  }
}

.greyName {
  color: $metal-bright-50;
}

.blackName {
  color: $midnight-black;
}

.hideStep {
  display: none;
}

.lastStepNoIcon {
  @include Breadcrumb__Breadcrumb--Active--Desktop;
  text-align: center;

  @media only screen and (min-width: $sm) {
    display: none;
  }
}
