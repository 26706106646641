@use 'style/index.module.scss' as *;

.container {
  padding: 16px 16px 36px;
}

.text {
  @include Body__Body-2--Desktop;
  line-height: 24px;
}

.sectionWrapper {
  padding: 16px 16px 0;
}

.sectionHeader {
  @include Body__Body-2--Bold--Desktop;
  margin-bottom: 2px;
}

.largeHeaderMargin {
  margin-bottom: 8px;
}

.trackingLink {
  cursor: pointer;
  margin-top: 6px;
}

.trackingId {
  @include Header__H4-Normal--Desktop;
  color: $sand-20;
  text-decoration: underline;
  margin-right: 12px;
}

.question {
  @include Body__Body-2--Desktop;
  background-color: $off-white-material;
  padding: 8px 16px 20px;
  margin-top: 16px;
}

.shipping {
  margin-top: 16px;
}

.sellerShipNowContainer {
  padding: 16px 16px 40px;
}

.receivedPayment {
  margin-bottom: 30px;
}

.sellerMarkedShipped {
  width: 100%;
  padding: 8px 16px 20px;
  background-color: $off-white-material;
}
