@use 'style/index.module.scss' as *;

.userInfoWrapper {
  display: flex;
  overflow: hidden;

  @media only screen and (min-width: $sm) {
    padding: 0 8px 8px;
  }
}

.avatar {
  height: 56px;
  width: 56px;
  margin-right: 8px;

  @media only screen and (min-width: $sm) {
    margin-right: 24px;
  }
}

.image {
  width: 100%;
  object-fit: contain;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.infoRow {
  height: 56px;
  width: 100%;
  padding-top: 8px;
  margin-bottom: 8px;
}

.name {
  @include Header__H4-Bold--Mobile;
}

.link {
  @include Body__Body-2--Desktop;
  color: $leather;
  text-decoration: underline;
  padding: 0px;
  text-align: left;
}

.email {
  @include Header__H4-Normal--Mobile;
  text-overflow: ellipsis;
  overflow: hidden;
  width: inherit;
}

.formContainerBody {
  padding: 0;
}
