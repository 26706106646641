@use 'style/index.module.scss' as *;

.header {
  @include Body__Body-2--Bold--Desktop;
  height: 48px;
}

.row {
  @include Subtitle__Subtitle-2-Midnight--Mobile;
  margin-top: 12px;
}

.buttonWrapper {
  margin: 24px 0 64px;
  justify-content: center;

  @media only screen and (min-width: $sm) {
    justify-content: flex-start;
  }
}

.buttonContainer {
  width: 100%;
}

.button {
  background-color: transparent;
}
