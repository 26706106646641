@use 'style/index.module.scss' as *;

.formContainer {
  background-color: $off-white-material;
  border-radius: 8px;
  border: solid 1px $metal-alpha-30;
  padding: 16px;
  margin-bottom: 85px;
}

.avatar {
  border: solid 2px $white;
  border-radius: 120px;
  width: 120px;
  height: 120px;
}

.uploadContainer {
  width: 120px;
  height: 120px;
  margin-bottom: 40px;
  position: relative;
}

.image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  text-align: center;
}

.cameraIcon {
  position: absolute;
  top: 90px;
  right: -4px;
}

.defaultImage {
  width: 90%;
  height: 90%;
}

.baseStyle {
  flex: 1;
  display: flex;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.focusedStyle {
  border-color: #2196f3;
  opacity: 50%;
}

.acceptStyle {
  border-color: #00e676;
  opacity: 50%;
}

.rejectStyle {
  border-color: #ff1744;
}

.input,
.nameExplination {
  margin-bottom: 48px;
}

.nameInput {
  margin-bottom: 8px;
}

.nameExplination {
  @include Body__Body-3--Desktop;
  color: $metal;
  text-align: right;
}

.buttonsContainer {
  border-top: solid 1px $metal-alpha-30;
  padding: 20px 0 12px;
  display: flex;
  justify-content: space-between;
}

.actionButton {
  width: 200px;
  height: 48px;
}

.formHeader {
  @include Body__Body-2--Bold--Desktop;
}

.bodyText {
  @include Specialized__Table__Left--Regular--Desktop;
  margin: 12px 0 24px;
}

.buttonsContainer.password {
  justify-content: flex-end;
}

.yardCertifiedHeaderContainer {
  display: flex;
  align-items: center;
}

.contactUs {
  padding-left: 0;
}

.deleteAccountContainer {
  border-top: solid 1px $metal;
  margin: 180px 0 50px;
}

.deleteAccount {
  @include Button--Midnight--Desktop;
  height: 56px;
  margin: 40px 0;
  padding-left: 0;
}

@media only screen and (min-width: $desktop) {
  .cancelButton {
    width: 150px;
  }
}
