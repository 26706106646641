@use 'style/index.module.scss' as *;

.headerWrapper {
  text-align: center;
  padding: 15px;

  @media only screen and (min-width: $desktop) {
    display: none;
  }
}

.title {
  @include Header__H2--Desktop;
}

.subtitle {
  @include Subtitle__Subtitle-2-Midnight--Mobile;
}

.bottomCta {
  padding: 50px 0 30px;
  margin: 0 8px;

  @media only screen and (min-width: $sm) {
    max-width: $maxWidth;
    padding: 0;
    margin: 50px auto;
  }
}

.sectionContainer {
  margin-bottom: 60px;
}

.latestItemsContainer {
  padding: 20px 0;
  background-color: $off-white-material;
}
