@use 'style/index.module.scss' as *;

.modalTopButtons,
.modalHeaderContainer {
  background-color: $midnight-black;
}

.modalHeaderContainer {
  min-height: 192px;
  height: fit-content;
  padding: 0 0 40px 16px;
  box-shadow: 0 10px 10px 2px rgba(199, 151, 105, 0.3);
}

.header {
  @include Header__H1--Mobile;
  color: $white;
}

.resultsButtonContainer {
  margin-top: 40px;
}

.showResultsButton {
  border: solid 1px $white;
  width: 100%;
}

.menu {
  margin-bottom: 40px;
  max-width: 100%;

  .headerTextContianer {
    height: 275px;
  }

  .menuContainer {
    height: 275px;
  }
}

.filterSortBy {
  @include Header__H6--Desktop;
  margin: 30px 16px 20px;

  @media only screen and (min-width: $sm) {
    margin-left: 0;
  }

  @media only screen and (min-width: $md) {
    @include Subtitle__Subtitle-2--Midnight--Desktop;
    margin: 12px 0 24px;
  }
}

.checkbox {
  height: 48px;
  min-height: 48px;
  text-transform: capitalize;
}

.checkboxLabel {
  @include Body__Body-2--Desktop;
  font-weight: normal;
}

.filtersContainer {
  @media only screen and (min-width: $desktop) {
    flex-direction: column;
  }
}

.modalMenus {
  @media only screen and (min-width: $sm) {
    margin: auto;
  }
}
