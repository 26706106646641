@use 'style/index.module.scss' as *;

.wrapper {
  display: flex;
  justify-content: center;
  margin: 40px auto 0;
  cursor: pointer;
}

.link {
  @include Specialized__Filter--Midnight--Desktop;
  padding-left: 0px;
  margin-left: 2px;
}
