@use 'style/index.module.scss' as *;

.modal {
  background-color: $off-white-material;
}

.wrapper {
  padding: 0 8px;

  @media only screen and (min-width: $md) {
    padding: 0;
  }
}

.maxWidth {
  @media only screen and (min-width: $md) {
    max-width: $maxWidth;
    margin: auto;
  }
}

.header {
  background-color: $off-white;
  padding-bottom: 24px;
}

.section {
  margin-bottom: 40px;
}

.noteContainer {
  margin: 0 8px 72px;

  @media only screen and (min-width: $sm) {
    margin: 0 0 72px;
  }
}

.addNote {
  @include Body__Body-2--Bold--Desktop;
  margin-bottom: 16px;
}

.logoHeader {
  padding: 40px 14px 0 0;
  justify-content: space-between;
  align-items: center;
}

.yardLogo {
  height: 38px;
  width: min-content;
  cursor: pointer;
}

.closeIcon {
  cursor: pointer;

  @media only screen and (min-width: $sm) {
    display: none;
  }
}

.title {
  @include Header__H1--Mobile;
  margin: 42px 0 16px;

  @media only screen and (min-width: $md) {
    @include Header__H1--Desktop;
    margin: 28px 0 32px;
  }
}

.productImage {
  display: none;

  @media only screen and (min-width: $md) {
    display: flex;
  }
}

.headerPriceCard {
  @media only screen and (min-width: $md) {
    display: none;
  }
}

.bodyContainer {
  padding-top: 32px;
  max-width: 100vw;

  @media only screen and (min-width: $sm) {
    justify-content: center;
  }

  @media only screen and (min-width: $md) {
    flex-direction: row-reverse;
    flex-grow: 1;
    column-gap: 16px;
  }
}

.rightSideBody {
  height: min-content;
}

.bodyPriceCard {
  display: none;

  @media only screen and (min-width: $md) {
    display: unset;
    height: min-content;
  }
}

.poweredByWrapper {
  margin-bottom: 18px;
  padding-right: 8px;

  @media only screen and (min-width: $md) {
    padding-right: 0;
  }
}

.poweredByStripe {
  width: 40%;

  @media only screen and (min-width: $md) {
    width: 30%;
  }
}

.buttonContainer {
  border-top: solid 4px $midnight-black;
  padding-top: 32px;
  height: 300px;
}

.checkoutButton {
  width: 100%;
}

.cancelButton {
  @include Specialized__Filter--Midnight--Desktop;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  cursor: pointer;
  margin-top: 32px;
}

.bodyFooter {
  display: none;

  @media only screen and (min-width: $md) {
    display: unset;
  }
}

.screenFooter {
  @media only screen and (min-width: $sm) {
    margin: auto;
  }

  @media only screen and (min-width: $md) {
    display: none;
  }
}

.loadingScreen {
  height: 90vh;
  display: flex;
  align-items: center;
}
