@use 'style/index.module.scss' as *;

.headerContainer {
  height: 48px;
  padding: 12px 0 8px;
  align-items: baseline;

  @media only screen and (min-width: $desktop) {
    margin-bottom: 8px;
  }
}

.featuredIn {
  @include Body-1--Desktop;
}

.category {
  @include Body-1--Desktop;
  font-weight: bold;
  text-transform: capitalize;
}

.seeAllLink {
  @include Body__Body-2--Desktop;
  color: $leather;
  padding-left: 0;
  margin-left: 8px;
}

.cardWrapper {
  max-width: 232px;

  @media only screen and (min-width: $md) {
    max-width: 240px;
  }
}

.featuredCard {
  cursor: pointer;
  position: relative;
  border-radius: 4px;
  height: 216px;
  background-color: $off-white-material;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12),
    0 1px 1px 0 rgba(0, 0, 0, 0.14);

  @media only screen and (min-width: $sm) {
    width: 224px;
    height: 265px;
  }
}

.featuredCard:hover {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14);
}

.imageContainer {
  height: 120px;
  width: 100%;
  flex-wrap: nowrap;
  overflow: hidden;

  @media only screen and (min-width: $sm) {
    height: 145px;
  }
}

.singleImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 4px 4px 0 0;
}

.multipleImagesWrapper {
  display: flex;
  overflow: hidden;
}

.twoImage {
  height: 100%;
  width: 50%;
  object-fit: cover;
}

.threeImage {
  height: 100%;
  width: 40%;
  object-fit: cover;
}

.firstImage {
  border-top-left-radius: 4px;
  margin-right: 2px;
}

.lastImage {
  border-top-right-radius: 4px;
}

.secondImage {
  margin-right: 2px;
}

.quantity {
  @include Subtitle__Subtitle-3--midnight--Desktop;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  border-radius: 15px;
  background-color: $white;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.14);
  position: absolute;
  height: 30px;
  min-width: 14px;
  top: calc(50% - 37.6px);
  right: 8px;

  @media only screen and (min-width: $desktop) {
    padding: 0 12px;
  }
}

.cardBottomContainer {
  padding: 10px;
  height: 96px;

  @media only screen and (min-width: $sm) {
    height: 120px;
  }
}

.subcategoryNumber {
  @include Breadcrumb__Breadcrumb--Inactive--Desktop;
  text-align: left;
}

.subcategoryName {
  @include Subtitle__Subtitle-2-Midnight--Mobile;
  margin-top: 5px;
  line-height: 16px;
  text-transform: capitalize;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  @media only screen and (min-width: $md) {
    @include Subtitle__Subtitle-2--Midnight--Desktop;
    line-height: 20px;
  }
}
