@use 'style/index.module.scss' as *;

.input {
  margin-bottom: 48px;
}

.form {
  margin-bottom: 40px;
}

.photoContainer {
  margin-top: 25px;
}

.photosContainer {
  max-height: fit-content;
  overflow-x: auto;
  flex-wrap: nowrap;
  padding-bottom: 10px;

  @media only screen and (min-width: $md) {
    padding-bottom: 0;
  }
}

.header {
  @include Body__Body-2--Bold--Desktop;
  margin-bottom: 10px;
}

.addPhotos {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  width: 64px;
  border-radius: 8px;
  margin-right: 10px;
  border: solid 1px $sand-20;
  background-color: $container-state-off-white-alpha-60;
}

.addPhotos.invalidPhotos {
  border: solid 2px $taurus-red-40;
}

.image {
  object-fit: contain;
  max-height: 190px;
  margin-right: 10px;
}

.warning {
  @include Subtitle__Subtitle-2-Midnight--Mobile;
  margin-bottom: 16px;
}

.sliderContainer {
  margin: 0 25px;
}

.sliderValue {
  @include Subtitle__Subtitle-2--Midnight--Desktop;
  margin: 20px 0;
}

.hazardousMaterialsContainer {
  padding: 16px;
  border-radius: 8px;
  border: solid 1px $metal-alpha-30;
}

.discloseHazard {
  @include Specialized__Caption--Desktop;
  margin-left: 25px;
}

.additionalDocumentsContainer {
  padding: 0 16px;

  @media only screen and (min-width: $md) {
    padding: 0;
  }
}

.documentExamples {
  border-bottom: solid 1px $metal-alpha-30;
  padding-bottom: 16px;
  margin-bottom: 10px;
}

.document {
  @include Body__Body-2--Desktop;
  padding: 8px 8px 8px 25px;
}

.documentNameContainer {
  display: flex;
  align-items: center;
}

.documentIcon,
.removeIcon {
  height: 20px;
  width: 20px;
}

.removeIcon {
  cursor: pointer;
}

.imageContainer {
  position: relative;
}

.invalidPhotos {
  color: $taurus-red-40;
  text-decoration: underline;
}

.threePhotosText {
  font-weight: bold;
}

.removeImageIcon {
  position: absolute;
  top: 2px;
  right: 12px;
  fill: $off-white-material;
  border-radius: 10px;
  background-color: $midnight-black;
}

.plusIcon {
  height: 24px;
}

.addDocuments {
  margin-top: 24px;
}

.addDocumentsButton {
  display: flex;
  align-items: center;
  height: 48px;
  padding: 12px 48px;
}
