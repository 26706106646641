@use 'style/index.module.scss' as *;

.modal {
  background-color: $off-white-material;
  border-radius: 4px;
}

.modal::-webkit-scrollbar-thumb {
  background-color: $metal;
  border: 4px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;
}

.modal::-webkit-scrollbar {
  width: 16px;
}

.modalButton {
  background-color: $off-white;
}

.headerWrapper {
  @include Header__H1--Mobile;
  background-color: $off-white;
  margin-bottom: 20px;
  padding: 0 8px 16px;
}

.formsWrapper {
  padding: 0 16px;
}

.formContainer {
  margin-bottom: 40px;
}

.saveButtonContainer {
  margin: 50px 0 40px;
  width: 100%;
}

.saveButton {
  width: 100%;
}

.backButton {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}
