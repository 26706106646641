@use 'style/index.module.scss' as *;

.addressTypeSection {
  border-top: solid 1px $metal-alpha-30;
  padding-top: 15px;
}

.headerText {
  @include Body__Body-2--Bold--Desktop;
  text-transform: capitalize;

  @media only screen and (min-width: $sm) {
    margin-bottom: 15px;
  }
}
