@use 'style/index.module.scss' as *;

.wrapper {
  cursor: pointer;
  height: 56px;
  width: 100%;
}

.disabledContainer {
  pointer-events: none;
}

.ripples {
  min-width: fit-content;
  border-radius: inherit;
  height: inherit;
  width: inherit;
}

.button {
  @include Button--Midnight--Desktop;
  border-radius: inherit;
  height: inherit;
  width: inherit;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rounded {
  border-radius: 24px;
  height: 48px;

  .button {
    padding: 0 30px;
  }
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  height: 52px;
}

/* disabled styling */
.primaryDisabled:disabled {
  background-color: $metal-alpha-30;
  pointer-events: none;
}

.secondaryDisabled:disabled {
  border: solid 2px $metal-alpha-30;
  color: $metal-alpha-30;
  pointer-events: none;
}

.textDisabled:disabled {
  color: $metal-alpha-30;
  pointer-events: none;
}

/* primary */
.sand20Primary {
  background-color: $sand-20;
  color: $white;
  border: none;

  &:hover {
    background-color: $sand-20-rollover;
  }

  &:focus {
    background-color: $sand-20-click;
  }
}

.sandPrimary {
  background-color: $sand;
  color: $white;
  border: none;

  &:hover {
    background-color: $sand-rollover;
  }

  &:focus {
    background-color: $sand-click;
  }
}

.brightSand20Primary {
  background-color: $sand-bright-20;
  color: $midnight-black;
  border: none;

  &:hover {
    background-color: $sand-bright-rollover;
  }

  &:focus {
    background-color: $sand-bright-click;
  }
}

.blackPrimary {
  background-color: $midnight-black;
  color: $white;
  border: none;

  &:hover {
    background-color: $midnight-rollover;
  }

  &:focus {
    background-color: $midnight-click;
  }
}

.whitePrimary {
  background-color: $white;
  color: $midnight-black;
  border: none;

  &:hover {
    background-color: $midnight-text-rollover;
  }

  &:focus {
    background-color: $midnight-text-click;
  }
}

.redPrimary {
  background-color: $taurus-red-60;
  color: $white;
  border: none;

  &:hover {
    background-color: $taurus-red-40;
  }

  &:focus {
    background-color: $taurus-red-20;
  }
}

/* secondary */
.sand20Secondary {
  border: solid 2px $sand-20;
  color: $sand-20;
  background-color: inherit;

  &:hover {
    background-color: $sand-20-rollover;
    color: $white;
  }

  &:focus {
    background-color: $sand-20-click;
    color: $white;
  }
}

.sandSecondary {
  border: solid 2px $sand;
  color: $sand;
  background-color: inherit;

  &:hover {
    background-color: $sand-rollover;
    color: $white;
  }

  &:focus {
    background-color: $sand-click;
    color: $white;
  }
}

.blackSecondary {
  border: solid 2px $midnight-black;
  color: $midnight-black;

  &:hover {
    background-color: $midnight-rollover;
    color: $white;
  }

  &:focus {
    background-color: $midnight-click;
    color: $white;
  }
}

/* text */
.sand20TextButton {
  background-color: $white;
  color: $sand-20;
  border: none;

  &:hover {
    background-color: $midnight-text-rollover;
    color: $white;
  }

  &:focus {
    background-color: $midnight-text-click;
    color: $white;
  }
}

.blackTextButton {
  background-color: $white;
  color: $midnight-black;
  border: none;

  &:hover {
    background-color: $midnight-text-rollover;
  }

  &:focus {
    background-color: $midnight-text-click;
  }
}

.whiteSandHoverText:hover {
  color: $white;
}
