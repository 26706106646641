@use 'style/index.module.scss' as *;

.documentContainer {
  height: 48px;
  align-items: center;
}

.icon {
  width: 20px;
  height: 20px;

  @media only screen and (min-width: $desktop) {
    width: 24px;
    height: 24px;
  }
}

.documentName {
  cursor: pointer;
}
