@use 'style/index.module.scss' as *;

.wrapper {
  border-top: solid 4px $midnight-black;
  padding: 45px 0 160px;
  justify-content: center;

  @media only screen and (min-width: $sm) {
    justify-content: space-between;
    padding: 92px 0 100px;
  }

  @media only screen and (min-width: $md) {
    justify-content: space-around;
  }
}

.documentsWrapper {
  margin-bottom: 116px;

  @media only screen and (min-width: $sm) {
    margin-bottom: 0;
  }
}

.documentsHeader {
  @include Subtitle__Subtitle-1--Leather--Mobile;
  display: flex;
  padding-bottom: 16px;
  border-bottom: solid 2px $midnight-black;

  @media only screen and (min-width: $md) {
    @include Subtitle__Subtitle-1--Leather--Desktop;
    line-height: 1;
    padding-bottom: 30px;
  }
}

.documentRow {
  height: 80px;
  border-bottom: solid 1px $metal-bright-50;
}

.removeATagCss {
  color: inherit;
  text-decoration: none;
}

.documentLeftSide {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.documentIcon {
  height: 38px;
  margin-right: 5px;
}

.sellerName {
  @include Body__Body-3--Desktop;
  color: $metal;
}

.documentName {
  font-size: 18px;
  color: $midnight-blackier;
}

.contactHeader {
  @include Body__Body-2--Bold--Desktop;
  margin-bottom: 12px;

  @media only screen and (min-width: $md) {
    margin-bottom: 27px;
  }
}

.contactText {
  @include Specialized__Table__Left--Regular--Desktop;
}

.buttonWrapper {
  margin-top: 32px;
  justify-content: center;

  @media only screen and (min-width: $md) {
    justify-content: flex-start;
  }
}

.buttonContainer {
  width: 100%;
}

.button {
  background-color: transparent;
}
