@use 'style/index.module.scss' as *;

.industryDropdown {
  margin-bottom: 16px;
}

.whatIsThisLink {
  padding-left: 0;
}

.formInput {
  margin-bottom: 40px;
}

.businessForm {
  margin: 20px 0;
}

.individualWelcome {
  @include Body__Body-2--Desktop;
  margin-left: 8px;
}

.noVisibility {
  position: relative;

  .noVisibilityIcon {
    position: absolute;
    top: 17px;
    right: 15px;
    font-size: 20px;
    opacity: 0.5;
  }
}

.bottomAccountSection {
  margin-top: 48px;
}

.checkboxWrapper {
  width: 100%;
  margin-bottom: 16px;
  position: relative;
  padding: 5px 0;
  display: flex;
}

.checkbox {
  height: 40px;
  align-items: unset;
}

.checkboxAssistiveText {
  top: 48px;
}

.invalidCheckbox {
  border: solid 2px $taurus-red-40;
  border-radius: 4px;
  margin-bottom: 40px;
}

.checkboxLabel {
  @include Body__Body-1-Left--Mobile;
  font-weight: normal;
  line-height: 1.2;

  @media only screen and (min-width: $sm) {
    @include Body-1--Desktop;
    line-height: 1.2;
  }
}

.urlLink {
  margin: 0;
}

.hideBusinessToggle {
  height: 40px;
}

.hideBusinessDetails {
  padding: 5px;
}

.hideBusinessTopSection {
  position: relative;
  padding: 0 10px 10px;
}

.hideBusinessSample {
  position: absolute;
  opacity: 0.3;
  color: $sand-20;
  font-size: 32px;
  font-weight: bold;
  left: 40px;
}

.anonymousName {
  @include Body-1--Desktop;
  font-weight: 500;
  line-height: 1.5;
}

.anonymousAddress {
  @include Body__Body-2--Desktop;
}

.hideBusinessBody {
  @include Body__Body-2--Desktop;
  padding: 0 10px 10px;
  margin-top: 24px;
}
