@use 'style/index.module.scss' as *;

.goodToKnowContainer {
  margin-top: 40px;

  @media only screen and (min-width: $desktop) {
    margin: 0;
  }
}

.header {
  @include Subtitle__Subtitle-1--Leather--Desktop;
  margin-bottom: 32px;
}

.goodToKnowItem {
  @include Body__Body-2--Desktop;
  height: 40px;
  margin-bottom: 24px;
}

.hazardousItem {
  @include Body__Body-2--Bold--Desktop;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  border-radius: 21px;
  padding-left: 8px;
  background-color: $off-white-material;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.14);
}

.hazardIcon {
  margin-right: 8px;
}

.forkliftIcon {
  width: 40px;
  height: 40px;
}
