.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.container {
  align-items: center;
  background-image: url('../../assets/images/dot_background-pattern1.png');
  display: flex;
  justify-content: center;
  padding: 24px 32px;
}

.container img {
  width: 100%;
}

.left {
  position: absolute;
  left: -100px;
}

.left img {
  width: 320px;
}

.right {
  position: absolute;
  right: -100px;
  align-items: start;
}

.right img {
  width: 320px;
}
