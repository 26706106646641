@use 'style/index.module.scss' as *;

.wrapper {
  position: relative;
}

/* special styling for quantity dropdown */
.quantityLabelWrapper {
  background-color: $white;

  .quantityLabel,
  .quantityLabel.transform {
    color: $midnight-black;
    padding: 0 2px;
    transform: translate(-3px, -10px) scale(0.75);
    background-color: $white;
  }
}
