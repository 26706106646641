@use 'style/index.module.scss' as *;

.wrapper {
  padding-bottom: 24px;
  border-radius: 8px;
  border: solid 1px $metal-alpha-30;
}

.headerContainer {
  height: 48px;
  padding: 0 8px;
  border-bottom: solid 1px $metal-alpha-30;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.header {
  @include Body__Body-2--Bold--Desktop;
  margin-bottom: 0;
}

.helpIcon {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.bodyContainer {
  padding: 0 8px;
}

.wrapper.hoverState:hover {
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.14);
  background-color: $off-white-material;
}

.wrapper.actionNeeded {
  box-shadow: 0 5px 5px -3px rgba(199, 151, 105, 0.2),
    0 3px 14px 2px rgba(199, 151, 105, 0.22),
    0 8px 10px 1px rgba(199, 151, 105, 0.19);
  background-color: $white;
}

.wrapper.restingState {
  background-color: $off-white-material;
}
