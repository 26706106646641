@use 'style/index.module.scss' as *;

.container {
  padding-bottom: 100px;

  @media only screen and (min-width: $sm) {
    flex-direction: column;
  }

  @media only screen and (min-width: $md) {
    padding-bottom: 0;
  }
}

.headerContainerStyling {
  background-color: $off-white;
  padding: 0 8px 24px;

  @media only screen and (min-width: $sm) {
    padding: 0 24px 64px;
  }

  @media only screen and (min-width: $md) {
    padding: 0 0 64px;
  }
}

.headerContainer {
  max-width: $maxWidth;

  @media only screen and (min-width: $sm) {
    margin: auto;
  }
}

.header {
  @include Header__H1--Mobile;
  padding-top: 70px;

  @media only screen and (min-width: $sm) {
    @include Header__H1--Desktop;
  }
}

.stepTrackerWrapper {
  margin-top: 16px;

  @media only screen and (min-width: $sm) {
    margin-top: 48px;
  }

  @media only screen and (min-width: $md) {
    margin-top: 60px;
  }
}

.bodyContainer {
  display: flex;
  justify-content: center;
  margin: 16px 8px;

  @media only screen and (min-width: $sm) {
    display: flex;
    margin: 32px auto;
  }
}

.formInput {
  margin-bottom: 16px;
}

.sectionContainer {
  border-top: solid 1px $metal-alpha-30;
  margin-top: 5px;
  padding-top: 25px;
}

.bottomAccountSection {
  margin-top: 24px;
}

.footer {
  border-top: solid 4px $midnight-black;
  margin: 0 8px;
  padding: 28px 32px 100px;
  justify-content: center;
  flex-direction: column;

  @media only screen and (min-width: $sm) {
    flex-direction: column;
    margin: 60px auto 25px;
    padding: 28px 80px 100px;
  }

  @media only screen and (min-width: $md) {
    padding: 28px 32px 100px;
  }
}

.nextButton {
  width: 100%;
}

.contactUsLink {
  @include Specialized__Filter--Midnight--Desktop;
  margin-top: 45px;
  text-align: center;
}
