@use 'style/index.module.scss' as *;

.buy-sell-switch__checkbox {
  height: 0;
  width: 0;
  position: absolute;
  visibility: hidden;
}

.buy-sell-switch-container {
  min-width: 250px;
  width: 100%;
  height: 48px;
  border-radius: 24px;
  background-color: $sand-bright-20;
  position: relative;
  margin: 0 8px;
  pointer-events: auto;

  @media screen and (min-width: $sm) {
    max-width: 250px;
    height: 36px;
    margin: 0 0 0 auto;
  }

  .buy-sell-switch__button {
    position: absolute;
    left: 0;
    min-width: 130px;
    min-height: 36px;
    height: 100%;
    width: 54%;
    transition: 0.2s;
    border-radius: 24px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
    background-color: $midnight-black;
    cursor: pointer;
  }
}

.buy-sell-switch__checkbox:checked + .buy-sell-switch__button {
  left: 100%;
  transform: translateX(-100%);
}

.buy-sell-switch__checkbox:active + .buy-sell-switch__button {
  width: 130px;
}

.buy-sell-switch__labels {
  position: relative;
  height: 100%;
  font-family: Roboto;
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.75px;
}

.buy-sell-switch__white-text {
  color: $white;
}

.buy-sell-switch__black-text {
  color: $midnight-black;
}

.buy-sell-switch__buy-label {
  left: 0;
}

.buy-sell-switch__sell-label {
  right: 0;
}

.buy-sell-switch__buy-label,
.buy-sell-switch__sell-label {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
}

.buy-sell-toggle__sell-dropdown {
  margin-left: 5px;
  height: 24px;
}

.buy-sell-toggle__sell-menu {
  top: 22px;
  right: -40px;
  width: 185px;
}

/* SwitchToggle */
.switch-toggle-default {
  display: flex;
  align-items: center;
}

.switch-toggle__toggle {
  display: flex;
}

.switch-toggle__checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.switch-toggle__label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 35px;
  height: 15px;
  background: $metal-bright-50;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
}

.switch-toggle__label .switch-toggle__button {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 45px;
  background: $white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12),
    0 1px 1px 0 rgba(0, 0, 0, 0.14);
}

.switch-toggle__checkbox:checked
  + .switch-toggle__label
  .switch-toggle__button {
  left: calc(100%);
  transform: translateX(-100%);
}

.toggle-default__checked {
  background-color: $sand-bright-20;
}

.toggle-default__button-checked {
  background-color: $sand !important;
}

.switch-toggle__text {
  margin-left: 10px;
}
