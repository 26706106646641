@use 'style/index.module.scss' as *;

.deleteAccountModal .modalButtons,
.headerContainer {
  background-color: $off-white;
}

.deleteAccountModal .deleteAccountContentWrapper {
  margin: 0;
  width: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.header {
  @include Header__H1--Mobile;
  padding: 50px 16px 40px;

  @media only screen and (min-width: $desktop) {
    margin: auto;
    width: 600px;
    padding: 20px 0 40px;
    text-align: center;
  }
}

.bodyContainer {
  margin: 16px 8px;

  @media only screen and (min-width: $desktop) {
    margin: 16px auto;
    width: 600px;
  }
}

.textWrapper {
  @include Body__Body-1-Left--Mobile;
  margin: 16px 8px 25px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 2;
}

.textHeader {
  font-weight: bold;
}

.actionContainer {
  border-radius: 8px;
  box-shadow: 0 5px 5px -3px rgba(199, 151, 105, 0.2),
    0 3px 14px 2px rgba(199, 151, 105, 0.22),
    0 8px 10px 1px rgba(199, 151, 105, 0.19);
  margin: 0 16px;
}

.checkboxWrapper {
  height: 72px;
  display: flex;
  align-items: center;
  background-color: $off-white-material;
  padding-left: 16px;
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
}

.confirmCheckboxLabel {
  @include Body__Body-2--Desktop;
  font-weight: normal;
}

.lineSeperator {
  width: 100%;
  height: 1px;
  opacity: 0.2;
  background-color: $metal;
}

.deleteButtonWrapper {
  background-color: $white;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
  padding: 16px;
}

.deleteButton {
  @include Button--white--Desktop;

  &:hover {
    background-color: $off-white;
  }
}

.deleteButtonContainer:hover {
}

.deleteButtonContainer {
  width: 100%;
}

.backButton {
  @include Button--Midnight--Desktop;
  height: 56px;
  margin: 35px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contactUsLink {
  width: fit-content;
  margin: 30px auto 50px;
  height: 56px;
}

.deleteAccountModal.inTheRed {
  background-color: $taurus-red-60;

  .modalButtons {
    background-color: $taurus-red-80;
    color: $white;
  }

  .wrapper.inTheRed {
    background-color: $taurus-red-60;

    .headerContainer {
      background-color: $taurus-red-80;

      .header {
        color: $white;
      }
    }

    .bodyContainer {
      background-color: $white;
    }

    .actionContainer {
      .checkboxWrapper {
        background-color: $taurus-red-20;
      }

      .confirmCheckboxLabel {
        color: $white;
      }

      .lineSeperator {
        display: none;
      }

      .deleteButtonWrapper {
        background-color: $taurus-red-40;

        .deleteButton {
          color: $taurus-red-40;
          font-weight: bold;
        }
      }
    }

    .contactUsLink {
      color: $white;
    }
  }
}

.accountDeletedWrapper {
  text-align: center;
  padding-top: 50px;
}

.accountDeletedHeader {
  @include Header__H1--Mobile;
}

.accountDeletedSubheader {
  @include Subtitle__Subtitle-3-Midnight--Mobile;
}
