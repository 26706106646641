@use 'style/index.module.scss' as *;

.backdropHeader {
  display: block;
  position: absolute;
  font-family: ParalucentStencil-Medium;
  -webkit-text-stroke: 2px #d8d8d8;
  font-size: 200px;
  line-height: 0.9;
  color: transparent;
  opacity: 0.2;
  max-width: 98vw;
  overflow: hidden;
  z-index: -1;

  @media only screen and (min-width: $sm) {
    font-size: 350px;
  }

  @media only screen and (min-width: $md) {
    font-size: 600px;
    line-height: 0.83;
  }
}
