@use 'style/index.module.scss' as *;

.bodyWrapper {
  @media only screen and (min-width: $md) {
    max-width: $maxWidth;
    margin: auto;
  }
}

.bannerWrapper {
  padding: 0 8px;

  @media only screen and (min-width: $sm) {
    padding: 0;
    margin: auto;
  }
}

.cardsWrapper {
  padding: 0 8px;
  margin-top: 48px;

  @media only screen and (min-width: $sm) {
    padding: 0;
    justify-content: center;
  }

  @media only screen and (min-width: $md) {
    max-width: $maxWidth;
    margin: 56px auto 0;
  }

  @media only screen and (min-width: $xl) {
    justify-content: flex-end;
  }
}

.cardContainer {
  @media only screen and (min-width: $sm) {
    justify-content: center;
  }

  @media only screen and (min-width: $md) {
    justify-content: space-between;
  }

  @media only screen and (min-width: $xl) {
    justify-content: space-between;
  }
}

.cardItem {
  margin-bottom: 40px;
}

.sectionContainer {
  @media only screen and (min-width: $sm) {
    justify-content: center;
  }

  @media only screen and (min-width: $md) {
    max-width: $maxWidth;
    margin: auto;
    justify-content: space-around;
  }

  @media only screen and (min-width: $xl) {
    justify-content: flex-end;
  }
}

.sellerSectionContainer {
  padding: 0 8px;

  @media only screen and (min-width: $sm) {
    margin: auto;
  }

  @media only screen and (min-width: $md) {
    padding: 0;
    max-width: $maxWidth;
  }
}

.timelineWrapper {
  padding: 0 8px;
  margin: 150px 0 60px;

  @media only screen and (min-width: $md) {
    padding: 0;
  }
}

.timelineAndPurchasesWrapper {
  position: relative;
}

.backgroundPurchases {
  display: none;

  @media only screen and (min-width: $md) {
    display: block;
    position: absolute;
    bottom: -170px;
    left: -30px;
    font-family: ParalucentStencil-Medium;
    -webkit-text-stroke: 2px #d8d8d8;
    font-size: 450px;
    color: transparent;
    opacity: 0.2;
    max-width: 100vw;
    overflow: hidden;
    z-index: -1;
  }

  @media only screen and (min-width: $lg) {
    max-width: $maxWidth;
    left: 0;
  }
}

.documentsContainer {
  background-color: $off-white;
  padding: 0 8px;

  @media only screen and (min-width: $sm) {
    padding: 0 16px;
  }

  @media only screen and (min-width: $md) {
    padding: 0;
  }
}

.loadingScreen {
  height: 500px;
  align-items: center;
}

.bottomSection {
  @media only screen and (min-width: $xl) {
    justify-content: flex-end;
  }
}
