@use 'style/index.module.scss' as *;

.wrapper {
  position: relative;
}

.helpIcon {
  position: absolute;
  right: 8px;
  top: 16px;
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.dollarSign {
  position: absolute;
  bottom: 12px;
  left: 16px;
}

.dollarInput {
  padding-left: 25px;
}

/* hide arrows in number input */
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}
