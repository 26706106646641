@use 'style/index.module.scss' as *;

.modal {
  height: fit-content;
  top: 20%;

  @media only screen and (min-width: $sm) {
    top: unset;
  }
}

.wrapper {
  padding: 35px 16px 60px;
}

.text {
  @include Subtitle__Subtitle-3-Midnight--Mobile;

  @media only screen and (min-width: $sm) {
    @include Subtitle__Subtitle-2--Midnight--Desktop;
    line-height: 1.4;
  }
}

.textLink {
  color: $sand-20;
  padding: 0;
}

.formWrapper {
  margin: 48px 0;
}

.input {
  margin-bottom: 40px;
}

.passwordInput {
  margin: 48px 0;
}

.buttonWrapper {
  padding: 0 8px;
}

.button {
  width: 100%;
}

.cancelButton {
  display: flex;
  align-items: center;
}

.missingInfoMessage {
  @include Body__Body-2--Bold--Desktop;
  margin-top: 40px;
  color: $taurus-red-40;
  text-align: center;
}
