@use 'style/index.module.scss' as *;

.wrapper {
  @media only screen and (min-width: $md) {
    @include Dot-Background;
    border: solid 1px $metal-alpha-30;
    padding: 16px;
  }
}

.imageBackground {
  @include Dot-Background;
  height: 24px;

  @media only screen and (min-width: $md) {
    display: none;
  }
}

.inputButtonContainer {
  display: flex;
  position: relative;
  height: 48px;
  margin: 8px 0 16px;

  @media only screen and (min-width: $md) {
    margin: 28px auto;
  }
}

.searchInput {
  height: 100%;
  flex: 1;
}

.input {
  padding-left: 8px;
  background-color: $white;
  border-radius: 0;
}
