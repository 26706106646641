@use 'style/index.module.scss' as *;

.container {
  flex-direction: column;
  width: inherit;
  margin: 0 8px;
  padding: 8px 0;

  @media only screen and (min-width: $sm) {
    flex-direction: row;
  }
}

.topBackground {
  @include Dot-Background;
  height: 16px;

  @media only screen and (min-width: $sm) {
    height: unset;
  }
}

.textContainer {
  padding: 8px;
  margin: 8px 0;
  border: 1px solid $metal-alpha-30;
  flex-wrap: nowrap;

  @media only screen and (min-width: $sm) {
    padding: 40px 0 32px 15px;
    margin: 0 16px;
    height: max-content;
  }
}

.title {
  @include Header__H4-Bold--Mobile;
  margin-bottom: 8px;

  @media only screen and (min-width: $sm) {
    @include Header__H4-Normal--Desktop;
    font-weight: normal;
    line-height: 32px;
    margin-bottom: 16px;
  }
}

.subtitle {
  @include Specialized__Table__Left--Regular--Desktop;
}

.buttonContainer {
  @include Dot-Background;

  @media only screen and (min-width: $sm) {
    align-items: center;
  }
}

.button {
  width: 100%;
  margin: 24px 0;

  @media only screen and (min-width: $sm) {
    margin: 0;
  }
}

.innerButton {
  display: flex;
  align-items: center;
  justify-content: center;
}
