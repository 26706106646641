@use 'style/index.module.scss' as *;

.container {
  margin-top: 25px;

  @media only screen and (min-width: $desktop) {
    margin: 0 0 65px;
  }
}

.header {
  border-bottom: none;
}

.conditionContainer {
  background-color: $off-white-material;
  padding: 30px 22px 34px 21px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.14);
}

.conditionName {
  @include Header__H4-Bold--Mobile;
  margin-bottom: 16px;

  @media only screen and (min-width: $desktop) {
    @include Header__H4-Bold--Desktop;
  }
}

.conditionDescription {
  @include Specialized__Caption--Desktop;
  padding-top: 12px;
  border-top: solid 1px $metal-alpha-30;
}

.sellerName {
  color: $leather;
}
