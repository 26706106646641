@use 'style/index.module.scss' as *;

.description {
  @include Body__Body-1-Left--Mobile;
  margin-top: 40px;

  @media only screen and (min-width: $md) {
    @include Body-1--Desktop;
    margin-top: 0;
  }
}

.boldDescription {
  font-weight: bold;
}
