@use 'style/index.module.scss' as *;

.inputWrapper {
  margin-bottom: 48px;
}

.wrapper {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: 100%;
  padding-left: 0;
}

.individualCategoryNumber {
  border-right: solid 2px $sand;
  padding: 0 16px 0 8px;
  height: inherit;
  margin-right: 16px;
  display: flex;
  align-items: center;
}

.optionContainer {
  flex-direction: column;
  align-items: flex-start !important;
}

.fullCategoryNumber {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
}

.inputContainer {
  padding-left: 6px;
}

.hideCursor {
  caret-color: transparent;
}

.categoryName {
  max-width: 80%;

  @media only screen and (min-width: $md) {
    max-width: unset;
  }
}
