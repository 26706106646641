@use 'style/index.module.scss' as *;

.radioButtonContainer {
  height: 42px;
  display: flex;
  align-items: center;
  padding-left: 2px;
}

.label {
  @include Body__Body-2--Desktop;
}

.input {
  margin-right: 10px;
}

.input:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: $white;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid $sand;
}

.input:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: $midnight-black;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid $midnight-black;
  box-shadow: 0px 0px 0px 2px $white inset;
}
