@use 'style/index.module.scss' as *;

.loggedIn {
  background-color: $off-white-material;
}

.contentContainer {
  display: flex;
  position: relative;
  min-height: 90vh;
  padding-top: 25px;
}

.headerContainer {
  @media only screen and (min-width: $md) {
    padding-left: 56px;
  }
}

.topLogOut {
  @include Body__Body-3--Desktop;
  color: $leather;
}

.bodyContainer {
  @media only screen and (min-width: $md) {
    padding-top: 64px;
  }
}

.welcomeContainer {
  @include Body__Body-3--Desktop;
  display: flex;
  padding-top: 3px;
  padding-left: 10px;
}

.shoppingContainer {
  padding-left: 5px;
}

.ordersButton {
  margin: 14px 0 8px;
}

.logoWrapper {
  width: 60px;
  display: flex;
  align-items: center;
  position: relative;

  @media only screen and (min-width: $md) {
    width: 72px;
  }
}

.logo {
  width: 80px;
  position: absolute;
  top: 24%;
  right: 0;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg); /* Safari and Chrome */
  -moz-transform: rotate(90deg); /* Firefox */
  -ms-transform: rotate(90deg); /* IE 9 */
  -o-transform: rotate(90deg); /* Opera */
}

.whatIsTheYard {
  @include Body-1--Desktop;
  padding-left: 0;
}

.buyingContainer {
  margin-top: 24px;
}

.sectionHeader {
  @include Header__H1--Mobile;
  margin-bottom: 16px;
}

.optionsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.menuOption {
  @include Body-1--Desktop;
  text-transform: capitalize;
  padding-left: 0;
  line-height: 40px;
}

.moreCatsToCome {
  @include Body__Body-2--Desktop;
  color: $metal;
  margin-top: $spacing-xs;
}

.sellingContainer,
.accountContainer {
  margin-top: 56px;
}

.accountHeader {
  @include Header__H4-Bold--Desktop;
  margin-bottom: $spacing-m;
}

.logout {
  @include Body__Body-3--Desktop;
  color: $leather;
  margin-top: 14px;
  padding-left: 0;
  line-height: 40px;
}

.bottomLinks {
  margin: 200px 0 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media only screen and (min-width: $md) {
    margin-bottom: 50px;
  }
}

.bottomLink {
  @include Body__Body-3--Desktop;
  height: 24px;
  padding-left: 0;
  display: flex;
  align-items: center;
}
