@use 'style/index.module.scss' as *;

.link {
  text-decoration: none;
  background-color: inherit;
  margin-right: 16px;
}

.sandTwenty {
  color: $sand-20;

  &:visited {
    color: $sand-20;
  }

  &:hover {
    color: $sand-20-rollover;
  }

  &:focus {
    color: $sand-20-click;
  }
}

.black {
  color: $midnight-black;

  &:visited {
    color: $midnight-black;
  }

  &:hover {
    color: $midnight-rollover;
  }

  &:focus {
    color: $midnight-click;
  }
}

.leather {
  color: $leather;

  &:visited {
    color: $leather;
  }

  &:hover {
    color: $leather-rollover;
  }

  &:focus {
    color: $leather-click;
  }
}

.sandBrightTwenty {
  color: $sand-bright-20;

  &:visited {
    color: $sand-bright-20;
  }

  &:hover {
    color: $sand-bright-rollover;
  }

  &:focus {
    color: $sand-bright-click;
  }
}

.sand {
  color: $sand;

  &:visited {
    color: $sand;
  }

  &:hover {
    color: $sand-rollover;
  }

  &:focus {
    color: $sand-click;
  }
}

.blue {
  color: $escrow-blue;

  &:visited {
    color: $escrow-blue;
  }

  /* @TODO: NT - Add hover / focus colors? */
}

.inactiveGrey {
  color: $inactive-grey;

  &:visited {
    color: $inactive-grey;
  }
}
