@use 'style/index.module.scss' as *;

.modal {
  min-width: fit-content;

  @media only screen and (min-width: $sm) {
    box-shadow: 0 5px 5px -3px rgba(199, 151, 105, 0.2),
      0 3px 14px 2px rgba(199, 151, 105, 0.22),
      0 8px 10px 1px rgba(199, 151, 105, 0.19);
    border-radius: 8px;
  }
}

.modal::-webkit-scrollbar-thumb {
  background-color: $metal;
  border: 4px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;
}

.modal::-webkit-scrollbar {
  width: 16px;
}

.savedPaymentSelected {
  height: fit-content;
}

.modalContentWrapper {
  height: 100%;
  display: flex;
  justify-content: center;

  @media only screen and (min-width: $sm) {
    height: unset;
    justify-content: unset;
  }
}

.elementsWrapper {
  display: flex;
  justify-content: center;
  position: relative;

  @media only screen and (min-width: $sm) {
    display: block;
    min-width: 500px;
    width: max-content;
  }
}

.loading {
  z-index: 999;
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: $container-state-off-white-alpha-60;
  display: flex;
  align-items: center;
}

.paymentForm {
  align-self: center;
  width: 90vw;
  padding: 24px 8px 45px;

  label {
    transform: translate(0, 16px) scale(1);
    left: 10px;
    pointer-events: none;
    position: absolute;
    transform-origin: top left;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }

  @media only screen and (min-width: $sm) {
    padding: 16px 16px 24px;
    width: max-content;
    min-width: 500px;
  }
}

.paymentMessage {
  color: $taurus-red-20;
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

.paymentElement {
  padding-bottom: 60px;
}

.bottomButtons {
  border-top: solid 4px $midnight-black;
  padding-top: 28px;
}

.submitButton {
  width: 100%;
}

.selectedSavedPaymentMethod {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 400px;
}
