@use 'style/index.module.scss' as *;

.checkboxWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox {
  display: flex;
}

.checkboxLabel {
  @include Body__Body-2--Desktop;
  margin-left: 8px;
}

.subLabel {
  @include Body__Body-3--Desktop;
  font-style: italic;
  margin-left: 10px;
}

.invalidLabel {
  color: $taurus-red-40;
}

.wrappedCheckboxWrapper {
  display: flex;
  height: 64px;
  margin: 8px 0;
  border-radius: 4px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 3px -2px rgba(0, 0, 0, 0.12),
    0 3px 4px 0 rgba(0, 0, 0, 0.14);
  background-color: #fff;
  align-items: center;
}

.wrappedCheckbox {
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: solid 1px #979797;
  height: 100%;
  width: 55px;
}

.wrappedCheckboxLabel {
  margin-left: 10px;
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  align-items: center;
  height: 100%;
}

.wrappedCheckboxIcon {
  margin-right: 3px;
  height: inherit;
  display: flex;
  align-items: center;
}
