@use 'style/index.module.scss' as *;

.container {
  background-color: inherit;
  padding-top: 25px;
  max-width: $maxWidth;
  padding: 0 8px;
  margin-bottom: 70px;

  @media only screen and (min-width: $md) {
    margin: 0 auto 100px;
    padding: 0;
  }
}

.header {
  text-align: center;
  margin-bottom: 35px;

  @media only screen and (min-width: $md) {
    margin-bottom: 60px;
  }
}

.title {
  @include Header__H1--Mobile;

  @media only screen and (min-width: $md) {
    @include Header__H1--Desktop;
  }
}

.subtitle {
  @include Subtitle__Subtitle-2-Midnight--Mobile;
  margin-top: 16px;
  padding: 0 8px;
}

.sectionTitle {
  @include Subtitle__Subtitle-1--Sand--Normal--Desktop;
  display: flex;
  height: 48px;
  align-items: center;

  @media only screen and (min-width: $md) {
    margin-bottom: 10px;
  }
}

.halfCardsContainer {
  flex-direction: column;

  @media only screen and (min-width: $sm) {
    flex-direction: row;
    justify-content: center;
    margin-bottom: 30px;
  }
}

.supportContainer {
  @media only screen and (min-width: $sm) {
    padding-right: 16px;
  }
}
