@use 'style/index.module.scss' as *;

.wrapper {
  background-color: $off-white-material;
  padding: 0 8px;

  @media only screen and (min-width: $desktop) {
    max-width: $maxWidth;
    margin: auto;
    padding-top: 24px;
  }
}

.innerWrapper {
  display: flex;
  flex-direction: column;
}

.headerWrapper {
  cursor: default;
  margin-bottom: 16px;

  @media only screen and (min-width: $desktop) {
    height: 200px;
    width: 100%;
    margin-bottom: 0;
  }
}

.header {
  @include Header__H1--Mobile;
  margin: 0;
  width: min-content;
  padding-bottom: 8px;

  @media only screen and (min-width: $desktop) {
    @include Header__H1--Desktop;
    text-align: left;
  }
}

.businessName {
  font-family: Roboto;
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: 0.47px;
  font-weight: bold;
  color: $leather;
}

.bodyContainer {
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: $desktop) {
    flex-direction: row;
  }
}

.menus {
  @media only screen and (min-width: $desktop) {
    width: 28%;
  }
}

.menuDropdownContainer {
  margin-bottom: 25px;
}

.menuDropdownMenu {
  padding: 0;
}

.menuLink {
  text-align: left;
  padding: 0;
  height: 40px;
  padding: 0 8px;
}

.selectedMenuOption {
  color: $midnight-black;
  background-color: $white;
  font-weight: bold;
}

.mobileDropdownContainer {
  padding: 20px 0;
  border-top: solid 2px $midnight-black;
}

.mobileDropdownMenu {
  margin: 8px 0;
  border: none !important;
}

.mobileDropdown {
  border: none;
  background-color: $sand-20;
}

.dotBackground {
  height: 24px;
  background-image: url('../../assets/images/dot_background-pattern1.png');
}

.pageContainer {
  @media only screen and (min-width: $desktop) {
    width: 72%;
    padding-left: 100px;
  }
}

.pageHeader {
  @include Body-1--Desktop;
  display: flex;
  align-items: center;
  height: 48px;
  margin-bottom: 28px;
  border-bottom: solid 2px $midnight-black;
}
