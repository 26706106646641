@use 'style/index.module.scss' as *;

.searchWrapper {
  margin: 25px 0 48px;
  max-width: $maxWidth;

  @media only screen and (min-width: $md) {
    padding: 190px 0 205px;
    margin: 48px auto 60px;
  }
}

.ctaContainer {
  background-color: $off-white;
}

.ctaWrapper {
  padding: 56px 0 116px;
  margin: 0 8px;
  max-width: $maxWidth;
  border-top: solid 4px $midnight-black;

  @media only screen and (min-width: $md) {
    padding: 190px 0 205px;
    margin: 0;
  }
}
