@use 'style/index.module.scss' as *;

.container {
  padding: 16px 16px 36px;
}

.sectionContainer {
  margin-bottom: 16px;
}

.sectionHeader {
  @include Body__Body-2--Bold--Desktop;
}

.largeHeaderSpace {
  margin-bottom: 8px;
}

.text {
  @include Body__Body-2--Desktop;
}

.paidDate {
  @include Body-1--Desktop;
  line-height: 1.5;
}

.refundedDate {
  text-align: right;
}

.priceContainer {
  flex-wrap: nowrap;
  padding: 16px;
  margin: 16px 0;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.14);
}

.offWhite {
  background-color: $off-white-material;
}

.white {
  background-color: $white;
}

.priceActionNeeded {
  margin-top: 0;
  box-shadow: 0 5px 5px -3px rgba(199, 151, 105, 0.2),
    0 3px 14px 2px rgba(199, 151, 105, 0.22),
    0 8px 10px 1px rgba(199, 151, 105, 0.19);
}

.priceItem {
  @include Specialized__Table__Left--Regular--Desktop;
  margin-bottom: 16px;
}

.total {
  @include Body-1--Desktop;
  border-top: solid 1px $midnight-black;
  padding-top: 16px;
}

.questions {
  @include Body__Body-3--Desktop;
}

.detailsFrom {
  @include Specialized__Legal;
  margin-top: 8px;
  text-align: right;
}

.paymentInfo {
  display: flex;
  height: 24px;
  align-items: center;
}

.paymentMethodWrapper {
  display: flex;
  align-items: center;
  margin-right: 12px;
}

.paymentLogo {
  width: 24px;
}

.paymentMethod {
  font-size: 14px;
  font-weight: bold;
}

.sellerContainer {
  padding: 16px 16px 12px;
}

.howToBePaidContainer {
  background-color: $off-white-material;
  padding: 8px 16px;
}

.logoWrapper {
  margin: 8px 0 4px;
  height: 32px;
}

.stripeLogo {
  height: 32px;
}

.lockIcon {
  height: 20px;
  width: 20px;
  margin-left: 8px;
}

.paymentInfo {
  position: relative;
}

.viaStripe {
  @include Specialized__Legal;
  position: absolute;
  bottom: -8px;
}

.releasedFunds {
  @include Body__Body-2--Desktop;
  background-color: $escrow-blue;
  color: $white;
  padding: 8px 16px;
  margin-top: 8px;
}

.releasedFundsBold {
  font-weight: bold;
  margin-bottom: 8px;
}

.failedPayment {
  color: $taurus-red-20;
  margin-left: 10px;
  font-weight: 500;
}
