@use 'style/index.module.scss' as *;

.wrapper {
  min-width: min-content;
  width: 100%;
}

.sectionHeader {
  justify-content: space-between;
  align-items: center;
  height: 48px;
  margin: 0;
  min-width: max-content;
}

.sectionHeader.showMenu {
  border-bottom: solid 2px $midnight-black;

  .header {
    @include Specialized__Table__Left--Bold--Desktop;
  }
}

.sectionHeader.showMenu.actionNeeded {
  border-bottom: solid 2px $sand-20;
}

.sectionHeader.hideMenu {
  border-bottom: solid 1px $metal;

  .header {
    @include Subtitle__Subtitle-3-Midnight--Mobile;
  }
}

.headerCountContainer {
  display: flex;
  align-items: baseline;
}

.count {
  @include Body__Body-2--Desktop;
  color: $metal;
  margin-left: 6px;
}

.menuOptionsContainer {
  display: flex;
  flex-direction: column;
  padding: 8px 8px 24px;
  border: solid 1px $metal-alpha-30;
  max-height: 300px;
  overflow-y: auto;
}

.menuOptionsContainer.hideMenu {
  display: none;
}

.menuOptionsContainer.actionNeeded {
  box-shadow: 0 5px 5px -3px rgba(199, 151, 105, 0.2),
    0 3px 14px 2px rgba(199, 151, 105, 0.22),
    0 8px 10px 1px rgba(199, 151, 105, 0.19);
  border: none;
  border-radius: 0 0 8px 8px;
}

.headerTextContianer {
  display: flex;
  align-items: baseline;
}

.headerLink {
  @include Body__Body-2--Desktop;
  color: $leather;
  margin-left: 16px;
  cursor: pointer;
}

.arrowContainer {
  display: flex;
  justify-content: flex-end;
}
