@use 'style/index.module.scss' as *;

.topSectionContainer {
  padding: 0 8px;

  @media only screen and (min-width: $md) {
    padding: 0;
  }
}

.subHeader {
  @include Subtitle__Subtitle-1--Leather--Mobile;
  margin-bottom: 16px;

  @media only screen and (min-width: $md) {
    @include Subtitle__Subtitle-1--Leather--Desktop;
    margin-bottom: 36px;
  }
}

.subText {
  @include Body__Body-1-Left--Mobile;

  @media only screen and (min-width: $md) {
    @include Body-1--Desktop;
    line-height: 2;
  }
}

.formContainer {
  margin: 36px 0 40px;
}

.bodyText {
  @include Body__Body-2--Desktop;
  line-height: 24px;
}

.bottomMargin {
  margin-bottom: 120px;
}

.buttonWrapper {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}
