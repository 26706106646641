@use 'style/index.module.scss' as *;

.wrapper {
  margin-bottom: 48px;
}

.optionWrapper {
  padding: 4px 0;
  align-items: center;
}

.iconWrapper {
  display: flex;
  align-items: center;
  max-height: 45px;
}

.icon {
  max-width: 100%;
  width: 30px;
}

.infoWrapper {
  padding-left: 15px;
}

.defaultText {
  @include Body__Body-2--Desktop;
  margin-left: 15px;
  border: solid 1px $sand-20;
  border-radius: 4px;
  background-color: $sand-20;
  color: $white;
  padding: 2px 4px;
}

.dropdownWrapper {
  width: 100%;
}
