@use 'style/index.module.scss' as *;

.wrapper {
  margin: 60px auto 0;
  padding: 0 24px;
  max-width: 550px;
}

.formContentWrapper {
  padding: 0 8px;
}

.text {
  @include Body__Body-2--Desktop;
  margin-bottom: 24px;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media only screen and (min-width: $md) {
    margin: 24px auto 0;
  }
}

.resetButton {
  width: 100%;
}

.footer {
  margin-top: 22px;
  padding: 0 16px;
}

.bottomText {
  margin-top: 160px;
}

.emailLink {
  margin-top: 48px;
  padding: 0;
}
