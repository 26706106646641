@use 'style/index.module.scss' as *;

.tableWrapper {
  position: relative;
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 0 8px;
  box-sizing: border-box;

  @media only screen and (min-width: $desktop) {
    padding-top: 56px;
    width: 85%;
    padding: 0;
  }
}

.header {
  @include Body-1--Mobile;
  height: 40px;
  border-bottom: solid 1px $metal;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (min-width: $desktop) {
    @include Body-1--Desktop;
    justify-content: unset;
  }
}

.subHeader {
  font-family: Roboto;
  font-size: 14px;
  line-height: 1.14;
  letter-spacing: 0.44px;
  color: $leather;
  margin-left: 18px;
}

.subHeaderLink,
.headerIcon {
  cursor: pointer;
}

.emptyTableWrapper {
  margin: 80px 0 140px;
}

.emptyTableText {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.15px;
  text-align: center;
  color: #6d6d6d;
}

.noActionRow {
  height: 15px;
}

.sortFilterViewContainer,
.sortViewContainer,
.sortAndFilterWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
}

.sortAndFilterWrapper {
  @media only screen and (min-width: $desktop) {
    width: 25%;
  }
}

.sortByContainer,
.filterContainer {
  display: flex;
  align-items: center;
}

.sortBy,
.filters {
  font-size: 12px;
  letter-spacing: 0.4px;
  color: $midnight-black;
}

.sortByDropdownContainer {
  height: 32px !important;
  margin-left: 5px;
  font-size: 12px;
  border-radius: 24px;
  border: solid 1px $sand !important;
}

.content {
  display: flex;
  justify-content: center;
}

.bottomSection {
  width: 100%;
}

.seeAllWrapper {
  display: flex;
  justify-content: space-around;
  margin: auto;
  width: 50%;
  padding: 15px 0 30px;
}

.seeAllButton {
  width: 175px;
  height: 48px;
  border: solid 2px $sand-20;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1.75px;
  text-align: center;
  color: $sand-20;
}

.pageNavigationWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 35px;
}

.pageNavigationButton {
  width: 36px;
  height: 36px;
  border-radius: 30px;
  border: none;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.14);
  background-color: $off-white-material;
  position: relative;

  & > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

.pageNavigationCenterInfo {
  display: flex;
  align-items: center;
}

.pageNumbers {
  @include Specialized__Table__Right--Desktop;
  margin-right: 15px;
}

.showAllLink {
  @include Body__Body-2--Desktop;
  border: none;
  padding: unset;
  background-color: inherit;
  color: $leather;
}

/* singleRowView */
.singleRowContainer {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  padding-bottom: 15px;

  @media only screen and (min-width: $desktop) {
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-behavior: smooth;
    width: 950px;
  }
}

.rowScrollButton {
  @media only screen and (min-width: $desktop) {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    width: 56px;
    height: 56px;
    margin-top: 95px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
    background-color: $off-white-material;
  }
}

.leftRowScrollButton {
  @media only screen and (min-width: $desktop) {
    left: -80px;
  }
}

.rightRowScrollButton {
  @media only screen and (min-width: $desktop) {
    right: -65px;
  }
}
