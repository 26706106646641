@use 'style/index.module.scss' as *;

.dropdownWrapper {
  padding: 16px 16px 25px;
}

.backgroundColor {
  background-color: $white;
}

.inputsWrapper {
  padding: 0 16px 24px;
  margin-top: 16px;
}

.hideForm {
  display: none;
}

.headerText {
  @include Body__Body-2--Bold--Desktop;
  text-transform: capitalize;

  @media only screen and (min-width: $sm) {
    margin-bottom: 15px;
  }
}
