@use 'style/index.module.scss' as *;

.wrapper {
  padding: 70px 16px 100px;
}

.header {
  @include Header__H4-Bold--Desktop;
  line-height: 1.2;
  margin-bottom: 40px;
}

.email {
  color: $sand-20;
  overflow-wrap: break-word;
}

.text {
  @include Body__Body-2--Desktop;
}

.emailLink {
  margin-top: 20px;
  padding: 0;
}

.closeButtonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 80px;
}

.closeButton {
  background-color: transparent;
  width: 100%;
}

.buttonContainer {
  width: 200px;
}
