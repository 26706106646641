@use 'style/index.module.scss' as *;

.formWrapper {
  padding-bottom: 0;
}

.categoryDropdownWrapper {
  margin: 10px 0 38px;
}

.categoryHeader {
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.4px;
  color: $metal;
  margin-bottom: 5px;
}

.fullCategoryContainer {
  margin: 20px;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.25px;
  color: #6d6d6d;
}
