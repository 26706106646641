@use './index.module.scss' as *;

/* global styling for inputs, dropdowns, and textarea */

.outerWrapper {
  position: relative;
  width: 100%;
}

.defaultInput {
  height: inherit;
  background-color: inherit;
  border: none;
  padding-left: 16px;
  border-radius: 4px;

  &:focus {
    outline: none;
  }
}

.defaultInputWithLabel {
  padding-top: 16px;
}

.defaultInputWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 54px;
  font-size: 14px;
  color: $midnight-black;
  border-radius: 4px;
  border: solid 1px $sand-20;
  background-color: $container-state-off-white-alpha-60;

  .transform {
    transform: translate(0, 2px) scale(0.75);
    color: $sand-20;
  }
}

.defaultTextAreaWrapper {
  display: flex;
  flex-direction: column;
  position: relative;

  .defaultTextArea.filled {
    padding: 20px 16px 16px;
  }

  .transform {
    transform: translate(0, 4px) scale(0.75);
    color: $sand-20;
  }
}

.defaultTextArea {
  border-radius: 4px;
  border: solid 1px $sand-20;
  background-color: $container-state-off-white-alpha-60;
  width: 100%;
  padding: 16px;
  resize: none;
  height: 185px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.defaultTextArea.filled,
.defaultInputWrapper.filled {
  border: solid 2px $sand;
  background-color: $off-white-material;

  .defaultInputLabel {
    color: $sand-20;
  }
}

.defaultInputWrapper.disabledInput {
  border: solid 1px $off-white;
  background-color: $off-white-material;
  color: $metal;

  .defaultInputLabel {
    color: $metal;
  }
}

.defaultTextAreaWrapper:focus-within {
  .defaultTextArea {
    padding: 20px 16px 16px;
  }

  .defaultInputLabel {
    transform: translate(0, 4px) scale(0.75);
    color: $sand-20;
  }
}

.defaultTextArea:focus-within,
.defaultInputWrapper:focus-within {
  border: solid 2px $sand-20;
  background-color: $white;
  box-shadow: 0 5px 5px -3px rgba(199, 151, 105, 0.2),
    0 3px 14px 2px rgba(199, 151, 105, 0.22),
    0 8px 10px 1px rgba(199, 151, 105, 0.19);

  .defaultInputLabel {
    transform: translate(0, 2px) scale(0.75);
    color: $sand-20;
  }
}

.defaultInputLabel {
  @include Subtitle__Subtitle-2-Midnight--Mobile;
  transform: translate(0, 16px) scale(1);
  left: 16px;
  pointer-events: none;
  position: absolute;
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.defaultTextAreaWrapper .invalidUserInput,
.defaultInputWrapper.invalidUserInput {
  border: solid 2px $taurus-red-40;
  background-color: $white;

  .defaultInputLabel {
    color: $taurus-red-40;
  }
}

.invalidLabel {
  color: $taurus-red-40;
}

.assistiveText {
  @include Body__Body-3--Desktop;
  color: $metal;
  padding-top: 8px;
  position: absolute;
  right: 0;
}

.redAssistiveText {
  color: $taurus-red-40;
}

.clickableAssistiveText {
  cursor: pointer;
}

.defaultMuiInput {
  border-radius: 4px;

  :hover {
    background-color: initial;
  }

  :focus-within {
    background-color: $white;
    box-shadow: 0 5px 5px -3px rgba(199, 151, 105, 0.2),
      0 3px 14px 2px rgba(199, 151, 105, 0.22),
      0 8px 10px 1px rgba(199, 151, 105, 0.19);

    :hover {
      background-color: $white;
    }
  }
}

.defaultMuiInput > label {
  @include Subtitle__Subtitle-2-Midnight--Mobile;
}

.muiInputFilled > label,
.defaultMuiInput:focus-within > label {
  color: $sand-20;
}

.defaultMuiInput.muiInputEmpty {
  border: solid 1px $sand-20;
  background-color: $container-state-off-white-alpha-60;
}

.defaultMuiInput.muiInputEmpty:focus-within {
  border: solid 2px $sand-20;
  background-color: $white;
}

.defaultMuiInput.muiInputFilled {
  background-color: $off-white-material;
  border: solid 2px $sand;
}

.disabledMuiInput,
.muiInputFilled.disabledMuiInput {
  border: solid 1px $off-white;
  background-color: $off-white-material;
  cursor: not-allowed;
}

.defaultMuiInput.invalidUserInput {
  border: solid 2px $taurus-red-40;
  background-color: $white;
}

.defaultMuiInput.invalidUserInput > label {
  color: $taurus-red-40;
}
