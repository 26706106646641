@use 'style/index.module.scss' as *;

.container {
  @include Header__H4-Normal--Desktop;
  height: 70vh;
  padding-top: 50px;
  display: flex;
  justify-content: center;
  text-align: center;
}
