@use 'style/index.module.scss' as *;

.modalContent {
  padding: 0 8px;

  @media only screen and (min-width: $sm) {
    padding: 0;
    max-width: 600px;
    margin: auto;
  }

  @media only screen and (min-width: $md) {
    width: 500px;
  }
}

.modalButtons {
  @media only screen and (min-width: $sm) {
    padding: 24px 0 0;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 100px;
}

.header {
  @include Header__H4-Bold--Mobile;
  margin-left: 35px;

  @media only screen and (min-width: $md) {
    @include Header__H4-Bold--Desktop;
  }
}

.buttonContainer {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}

.nextButton {
  width: 150px;
}

.create-password__password-input {
  margin-bottom: 10px;
}

.create-account__password-strength-bar,
.create-password__password-strength-bar p {
  text-align: left !important;
  font-weight: bold;
}

.passwordRequirementsContainer {
  width: 302px;
  font-family: Roboto;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.6);
}

.requiredList {
  padding-left: 15px;
}

.emailTheYard {
  margin: 45px 0;
}

.text {
  @include Body-1--Mobile;
  padding: 50px 35px 0;

  @media only screen and (min-width: $md) {
    @include Body-1--Desktop;
  }
}

.closeButton {
  margin-top: 35px;
}
