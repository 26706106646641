@use 'style/index.module.scss' as *;

.maxWidth {
  @media only screen and (min-width: $md) {
    max-width: $maxWidth;
    margin: auto;
  }
}

.headerContainer {
  background-color: $off-white;
  padding: 20px 8px 45px;

  @media only screen and (min-width: $md) {
    padding: 24px 0 60px;
  }
}

.title {
  @include Header__H1--Mobile;
  text-transform: capitalize;
}

.searchBar {
  margin: 20px 0;
}
