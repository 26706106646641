@use 'style/index.module.scss' as *;

.sideMenuModal,
.modalButtonContainer {
  background-color: $off-white-material;
}

.modalButtonContainer {
  border-bottom: none !important;
}

.accountHome {
  @include Body-1--Desktop;
  padding-left: $spacing-xxl-2;
}

.optionsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.option,
.logOut {
  padding-left: 0;
  line-height: 40px;
}

.contentWrapper {
  padding-left: $spacing-xxl-4;
}

.sectionHeader {
  @include Header__H6--Desktop;
  margin-top: $spacing-xxl-4;
  margin-bottom: $spacing-m;
  line-height: 1;
}

.topSection {
  margin-top: $spacing-xxl;
}

.logOut {
  margin: 70px 0 20px;
}
