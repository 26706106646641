@use 'style/index.module.scss' as *;

.activeCartWrapper {
  position: relative;
}

.activeCart {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: $taurus-red-bright-20;
  color: $white;
  font-size: 12px;
  font-weight: bold;
  border-radius: 14px;
  height: 14px;
  width: 14px;
}

.menuActiveCart {
  top: -2px;
  left: 26px;
}

.headerActiveCart {
  top: 0px;
  right: -1px;

  @media screen and (min-width: $md) {
    top: -2px;
    right: -3px;
  }
}

.buttonWrapper {
  padding: 0;
  margin: 0;

  @media screen and (min-width: $sm) {
    margin: 0 8px;
  }
}

.cartButton {
  margin-right: 0;

  @media screen and (min-width: $md) {
    margin-left: 14px;
  }
}

.iconAvatar {
  height: 40px;
  width: 40px;

  @media screen and (min-width: $md) {
    height: 32px;
    width: 32px;
  }
}

.icon {
  width: 23px;
  height: 23px;

  @media screen and (min-width: $md) {
    height: 18px;
    width: 18px;
  }
}
