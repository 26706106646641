@use 'style/index.module.scss' as *;

.wrapper {
  display: flex;
  width: 100%;
  position: relative;
}

.input {
  @include Subtitle__Subtitle-3--midnight--Desktop;
  height: 48px;
  width: 100%;
  border: none;
  outline: none;
  padding-left: 8px;
  background-color: $off-white;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
}

.input::placeholder {
  @include Subtitle__Subtitle-3--midnight--Desktop;
}

.icon {
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 8px;
}
