@use 'style/index.module.scss' as *;

.accountWrapper {
  height: max-content;
}

.header {
  @include Body__Body-2--Bold--Desktop;
  margin-bottom: 16px;
}

.verified {
  @include Body__Body-2--Desktop;
  line-height: 1;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.stripeButtonContainer {
  margin-top: 40px;
}

.accountText {
  @include Specialized__Table__Left--Regular--Desktop;
}
