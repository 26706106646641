@use 'style/index.module.scss' as *;

.container {
  font-size: 0.875rem;
  letter-spacing: 1.67px;
  position: relative;
  padding-top: 8px;

  @media screen and (min-width: $sm) {
    padding-top: 0;
  }
}

.innerContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: $maxWidth;
  background-color: $off-white-material;
  margin: auto;
  padding-bottom: 8px;

  @media screen and (min-width: $sm) {
    padding-bottom: 0;
    height: 56px;
  }
}

.wrapper {
  align-items: center;
  display: flex;
  max-width: $maxWidth;
  width: 100%;
  height: inherit;

  @media screen and (min-width: $sm) {
    padding-left: 8px;
  }

  @media screen and (min-width: $md) {
    padding-left: 0;
  }
}

.innerContainer.collapse {
  @media screen and (min-width: 786px) {
    height: 0;
    pointer-events: none;
    top: 28px;
  }
}

.sellLinkWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.firstLinkChunk {
  @media screen and (min-width: $sm) {
    margin-right: 24px;
  }
}

.createListingLink {
  border: solid 2px $midnight-black;
  padding: 8px;

  &:hover {
    color: $white;
    background-color: $midnight-black;
  }
}
