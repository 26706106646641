@use 'style/index.module.scss' as *;

.wrapper {
  padding-bottom: 92px;

  @media only screen and (min-width: $md) {
    padding-bottom: 225px;
  }
}

.topSection {
  background-color: $off-white;
  padding-top: 0;
  margin: auto;

  @media only screen and (min-width: $md) {
    padding-top: 24px;
  }
}

.tabsWrapper,
.innerTopSection {
  @media only screen and (min-width: $sm) {
    margin: auto;
    max-width: $maxWidth;
  }
}

.headerWrapper {
  margin: auto;
  padding: 8px 8px 0;

  @media only screen and (min-width: $md) {
    padding: 0;
  }
}

.header {
  line-height: 1;
  margin: 0;

  @media only screen and (min-width: $md) {
    padding: 0;
  }
}

.headerCompany {
  @include Specialized__Table__Left--Link-Desktop;
  font-weight: bold;
  margin-top: 12px;
}

/**
 * NOTE:
 * These tab styles may need to be pulled into
 * a higher scope in the event that other pages
 * use the same styled tab components.
 */
.tabs {
  margin-top: 24px;

  @media only screen and (min-width: $md) {
    max-width: $maxWidth;
    margin: 108px auto 0;
  }
}

.tabIndicator {
  display: flex;
  justify-content: center;
  background-color: transparent;
  height: 4px;

  span {
    background-color: $midnight-black;
    height: 4px;
    width: 72%;
  }
}

.tab {
  color: $leather;
  font-size: 14px;
  width: 50%;

  @media only screen and (min-width: $sm) {
    width: 232px;
  }
}

.selectedTab {
  background-color: $white;
  color: $midnight-black;
}

.tableWrapper {
  padding: 100px 0;

  @media only screen and (min-width: $md) {
    max-width: $maxWidth;
    margin: auto;
    padding-top: 75px;
  }
}

.tableData {
  width: 100%;
}
