@use 'style/index.module.scss' as *;

.cardContainer {
  background-color: $off-white-material;
  padding: 16px 8px 30px;
  border: solid 1px $metal-alpha-30;
  border-radius: 8px;
  margin-bottom: 30px;

  @media only screen and (min-width: $sm) {
    padding: 16px 16px 30px;
    margin: 0 auto 30px;
  }
}

.halfCard {
  @media only screen and (min-width: $sm) {
    margin: 0;
    min-width: 100%;
  }
}

.cardContainer:hover {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14);
  background-color: $white;
  border: none;
}
