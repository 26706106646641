@use 'style/index.module.scss' as *;

.modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #fff;
  outline: none;
  overflow: auto;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.25);
}

.modalContentWrapper {
  display: flex;
  flex-direction: column;
}

.topButtonContainer {
  padding: 24px 20px 0;
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
  max-width: $maxWidth;
  margin: auto;
}

.closeButton,
.backButton {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.closeButton {
  height: 24px;
  width: 24px;
}

.showScrollWhenScrolling {
  overflow: overlay;
}

.hideScrollWhenResting {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none;
}

.hideScrollWhenResting::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.overlayScreen {
  @media only screen and (min-width: $desktop) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.overScreen {
  @media only screen and (min-width: $desktop) {
    height: 675px;
    width: 400px;
    top: unset;
    left: unset;
  }
}
