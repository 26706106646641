@use 'style/index.module.scss' as *;

.wrapper {
  background-color: $sand-20;
  color: $white;
  height: 96px;

  @media only screen and (min-width: $sm) {
    height: 56px;
  }
}

.innerWrapper {
  max-width: $maxWidth;
  margin: 0 auto;
  height: inherit;
  padding: 0 16px;

  @media only screen and (min-width: $sm) {
    height: 56px;
    padding: 0;
  }
}

.textWrapper {
  @include Body__Body-2--Desktop;
  line-height: 16px;
  color: $white;
  align-items: center;
}

.cancelButton {
  margin-right: 16px;
}

.button {
  padding-right: 0;
}
