@use 'style/index.module.scss' as *;

.form {
  background-color: $off-white-material;
  border-radius: 8px;
  border: solid 1px $metal-alpha-30;
  padding: 16px;
  margin-bottom: 85px;
}

.formHeader {
  @include Subtitle__Subtitle-2-Midnight--Mobile;
  font-weight: bold;
}

.formDescription {
  @include Body__Body-3--Desktop;
  margin-top: 8px;
}

.textArea {
  width: 100%;
  min-height: 150px;
  margin: 20px 0 40px;
  resize: none;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
}

.button {
  width: 125px;
  height: 48px;
}

.cancelButton {
  background-color: inherit;
}

@media only screen and (min-width: $desktop) {
  .button {
    width: 160px;
    height: 48px;
  }
}
