@use 'style/index.module.scss' as *;

.container {
  background-color: $midnight-blackier;
  color: $sand-bright-20;
  display: flex;
  flex-wrap: nowrap;
  font-size: 0.875rem;
  justify-content: center;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: $maxWidth;
  width: 100%;
}

.menu {
  @media screen and (min-width: $md) {
    padding-left: 0;
  }
}

.menuLabel {
  @include Body__Body-2--Desktop;
  color: $sand-bright-20;
  font-weight: normal;
}

.link {
  @include Body__Body-3--Desktop;
  color: $sand-bright-20;
}

.rightMargin {
  margin-right: 20px;
}

.noMargin {
  margin-right: 0;
}
