@use 'style/index.module.scss' as *;

.sellerContainer {
  border-top: solid 1px $metal;
  padding-top: 15px;
  margin-top: 40px;

  @media only screen and (min-width: $sm) {
    margin-top: 100px;
  }

  @media only screen and (min-width: $md) {
    flex-direction: row;
    padding-top: 0;
    border-top: none;
    width: 100%;
  }
}

.imageAvatar {
  border: solid 1px $white;
  width: 56px;
  height: 56px;

  @media only screen and (min-width: $md) {
    min-width: 80px;
    min-height: 80px;
    width: 100%;
    height: auto;
    margin: unset;
  }
}

.imageContainer {
  @media only screen and (min-width: $md) {
    padding: 48px 16px 0 0;
  }
}

.image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.infoContainer {
  margin-bottom: 22px;

  @media only screen and (min-width: $md) {
    border-top: solid 1px $metal;
    padding-top: 48px;
    justify-content: flex-end;
  }
}

.soldBy {
  @include Header__H4-Normal--Mobile;

  @media only screen and (min-width: $sm) {
    @include Header__H4-Normal--Desktop;
  }
}

.sellerName {
  @include Header__H4-Bold--Mobile;
  display: flex;
  align-items: center;

  @media only screen and (min-width: $sm) {
    @include Header__H4-Bold--Desktop;
  }
}

.addressAndMember {
  flex-direction: column;

  @media only screen and (min-width: $sm) {
    flex-direction: row;
    margin: 8px 0;
  }
}

.address {
  @include Body__Body-2--Bold--Desktop;
}

.memberSince {
  @include Body__Body-2--Desktop;

  @media only screen and (min-width: $sm) {
    margin-left: 8px;
  }
}

.ordersAndRating {
  margin-top: 8px;
}

.sellerRating {
  @include Body__Body-2--Bold--Desktop;
  display: flex;
}

.sixteenStar {
  width: 16px;
  height: 16px;
}

.ratingsCount {
  @include Body__Body-2--Desktop;
  color: $metal;
}

.buttonContainer {
  justify-content: center;

  @media only screen and (min-width: $md) {
    border-top: solid 1px $metal;
    padding-top: 48px;
    justify-content: flex-end;
  }
}

.viewSellerTerms {
  height: 56px;
  margin: 8px 0 40px;
}
