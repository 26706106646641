@use 'style/index.module.scss' as *;

.wrapper {
  padding: 8px;
  align-items: center;
  border: solid 1px $metal-alpha-30;

  &:hover {
    border-radius: 8px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14);
    background-color: $white;
  }
}

.defaultOptionWrapper {
  height: 56px;
}

.otherOptionWrapper {
  height: 48px;
}

.iconWrapper {
  display: flex;
  align-items: center;
}

.icon {
  max-width: 100%;
  width: 35px;
}

.defaultText {
  @include Body__Body-2--Desktop;
  margin-left: 15px;
  border: solid 1px $sand-20;
  border-radius: 4px;
  background-color: $sand-20;
  color: $white;
  padding: 2px 4px;
}

.dropdownMenu {
  min-width: 100px;
}
