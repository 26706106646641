@use 'style/index.module.scss' as *;

.wrapper {
  margin-bottom: 100px;
}

.formContainer {
  background-color: $off-white-material;
  border-radius: 8px;
  border: solid 1px $metal-alpha-30;
  padding: 16px 16px 20px;
  margin-bottom: 15px;
  height: fit-content;
}

.header {
  @include Body__Body-2--Bold--Desktop;
  margin-bottom: 16px;
}

.topForm {
  margin-bottom: 40px;

  @media only screen and (min-width: $sm) {
    margin-bottom: 80px;
  }
}

.stripeLogoContainer {
  height: 32px;
  margin-left: -6px;
  display: flex;
  justify-content: flex-end;
}

.stripeLogo {
  height: inherit;
}

.paymentOptionWrapper {
  margin-bottom: 36px;
}
