@use 'style/index.module.scss' as *;

.container {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 56px;
  padding-left: 8px;

  @media screen and (min-width: $md) {
    padding-left: 0;
  }
}

.wrapper {
  align-items: center;
  display: flex;
  font-size: 0.625rem;
  max-width: $maxWidth;
  width: 100%;

  @media screen and (min-width: $md) {
    margin-left: 85px;
  }
}

.grey {
  background-color: $off-white;
}

.offWhite {
  background-color: $off-white-material;
}

.breadcrumb {
  @include Breadcrumb__Breadcrumb--Desktop;
  text-decoration: none;
  background-color: inherit;
  margin-right: 10px;

  @media screen and (min-width: $md) {
    margin-right: 16px;
  }
}

.inactive {
  @include Breadcrumb__Breadcrumb--Inactive--Desktop;
}

.active {
  @include Breadcrumb__Breadcrumb--Active--Desktop;
}

.important {
  @include Breadcrumb__Important--Desktop;
}
