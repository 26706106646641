@use 'style/index.module.scss' as *;

.footer {
  position: relative;
  background-color: $midnight-black;
  padding: 57px 8px 100px;
  justify-content: flex-end;

  @media only screen and (min-width: $sm) {
    justify-content: center;
    position: unset;
    padding: 77px 0 80px;
  }

  @media only screen and (min-width: $xl) {
    padding: 90px 0 100px;
  }
}

.maxWidth {
  @media only screen and (min-width: $sm) {
    justify-content: center;
  }

  @media only screen and (min-width: $md) {
    max-width: $maxWidth;
    margin: auto;
    justify-content: center;
  }
}

.topSection {
  position: relative;

  @media only screen and (min-width: $sm) {
    justify-content: flex-start;
  }
}

.lists {
  justify-content: center;

  @media only screen and (min-width: $sm) {
    min-height: 250px;
    justify-content: space-between;
  }

  @media only screen and (min-width: $md) {
    justify-content: unset;
  }
}

.listHeader {
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  color: $metal;
  margin-bottom: 20px;

  @media only screen and (min-width: $sm) {
    font-size: 24px;
  }
}

.listItem {
  @include Specialized__UL--White--Desktop;
  cursor: pointer;
}

.list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin-top: 30px;

  @media only screen and (min-width: $sm) {
    min-width: fit-content;
    position: relative;
    margin-top: 0;
  }
}

.lastItem {
  @media only screen and (min-width: $lg) {
    position: absolute;
    bottom: 0;
  }
}

.taglineWrapper {
  display: none;
  min-width: max-content;

  @media only screen and (min-width: 385px) {
    display: block;
    position: absolute;
    bottom: -50px;
    right: 8px;
    text-align: right;
  }

  @media only screen and (min-width: $sm) {
    top: 0;
  }

  @media only screen and (min-width: $md) {
    position: unset;
    text-align: left;
    margin-top: 0;
  }

  @media only screen and (min-width: $lg) {
    padding-left: 15px;
  }
}

.taglineText {
  font-family: ParalucentStencil-Medium;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 1.7px;
  pointer-events: none;
  overflow-wrap: break-word;
  width: 100%;

  @media only screen and (min-width: $sm) {
    line-height: 0.89;
    letter-spacing: 2.2px;
  }
}

.redText {
  color: #ed1f2c;
}

.whiteText {
  color: $white;
}

.smallBigText {
  font-size: 45px;

  @media only screen and (min-width: $sm) {
    font-size: 50px;
  }
}

.bigText {
  font-size: 60px;

  @media only screen and (min-width: $sm) {
    font-size: 72px;
  }
}

.bottomSection {
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  margin-top: 80px;

  @media only screen and (min-width: $sm) {
    margin-top: 150px;
    justify-content: center;
  }

  @media only screen and (min-width: $md) {
    flex-wrap: wrap;
    margin-top: 100px;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-end;
  }
}

.copyright {
  font-size: 10px;
  color: $metal;
  line-height: 1.2;
  margin: auto;

  @media only screen and (min-width: $sm) {
    line-height: 1.6;
    margin: unset;
  }

  @media only screen and (min-width: $md) {
    padding-bottom: 3px;
    margin: unset;
  }
}

.logoContainer {
  display: flex;
  justify-content: flex-end;
  margin: 5px 0 36px;

  @media only screen and (min-width: $sm) {
    margin: 0 auto 25px;
  }

  @media only screen and (min-width: $md) {
    margin: unset;
  }
}

.logo {
  height: 50px;
  width: 165px;

  @media only screen and (min-width: $md) {
    width: 100%;
    height: 60px;
  }
}

.releaseInfo {
  color: $metal;
  font-size: 12px;
  font-weight: bold;
}
