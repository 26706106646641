@use 'style/index.module.scss' as *;

.imagesContainer {
  height: 224px;

  @media only screen and (min-width: $sm) {
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-behavior: smooth;
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media only screen and (min-width: $md) {
    height: 600px;
    max-width: $maxWidth;
    margin: auto;
    padding: 0;
  }
}

.imageContainer {
  height: 224px;
  min-width: 300px;
  margin-right: 8px;
  overflow: hidden;

  @media only screen and (min-width: $desktop) {
    height: 100%;
    min-width: 750px;
  }
}

.imageCarouselModal {
  height: min-content;
  top: 100px;
  overflow-x: hidden;

  @media only screen and (min-width: $desktop) {
    top: 5px;
    left: 15vw;
    width: 70vw;
    height: 98vh;
  }
}

.slide {
  margin-right: 16px;
}

.modalImageContainer {
  margin: 16px 8px 32px;
  justify-content: center;

  @media only screen and (min-width: $md) {
    max-height: 90vh;
  }

  .carouselContainer {
    @media only screen and (min-width: $desktop) {
      height: 90vh;
    }
  }

  .carousel {
    @media only screen and (min-width: $desktop) {
      height: 80vh;
    }
  }

  .slider {
    width: inherit;
    object-fit: contain;

    @media only screen and (min-width: $desktop) {
      height: 78vh;
    }
  }

  .slide {
    height: inherit;

    @media only screen and (min-width: $desktop) {
      height: 78vh;
    }
  }

  .fullImage {
    height: 100%;
    width: 100%;
    object-fit: contain;

    @media only screen and (min-width: $md) {
      height: 78vh;
    }
  }
}

.pdpImageContainer {
  height: 100%;
  width: 100%;

  .slider {
    overflow: visible;
  }

  .carouselContainer,
  .carousel,
  .slider,
  .sliderTrayWrapper,
  .sliderTray,
  .slide,
  .innerSlide {
    height: 100%;
  }

  .carouselContainer {
    max-width: 750px;
  }

  .sliderTray {
    max-width: max-content;
  }

  .slider {
    width: inherit;
    object-fit: contain;
  }

  .slide {
    min-width: max-content;
    max-width: max-content;
  }

  .fullImage {
    height: 100%;
    object-fit: contain;
    max-width: 750px;
  }
}

.navButtonsContainer {
  margin-top: 16px;
}

.buttonWrapper {
  display: none;

  @media only screen and (min-width: $sm) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    position: absolute;
    top: calc(50% - 40px);
  }
}

.navButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  width: 56px;
  height: 56px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
  background-color: $off-white-material;
}

.leftButton {
  @media only screen and (min-width: $sm) {
    left: 8px;
  }
}

.rightButton {
  @media only screen and (min-width: $sm) {
    right: -11px;
  }
}

.icon {
  width: 26px;
  height: 26px;
}
