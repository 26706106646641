@use 'style/index.module.scss' as *;

.wrapper {
  padding: 16px 16px 24px;
  border: solid 1px $metal-alpha-30;
}

.pricingContainer {
  padding: 16px;
  background-color: $white;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.14);
}

.priceLine {
  @include Specialized__Table__Left--Regular--Desktop;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.totalContainer {
  @include Body-1--Desktop;
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
  border-top: solid 1px $midnight-black;
}

.total {
  font-weight: bold;
}

.seller {
  @include Body__Body-2--Desktop;
  margin: 16px 0 8px;
  font-weight: bold;
}

.sellerName {
  @include Body__Body-2--Desktop;
}

.stateAndRating {
  @include Subtitle__Subtitle-3-Midnight--Mobile;
  margin-top: 4px;
  display: flex;
}

.rating {
  font-weight: bold;
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.starIcon {
  width: 16px;
  height: 16px;
}

.productImage {
  margin-bottom: 24px;

  @media only screen and (min-width: $md) {
    display: none;
  }
}
