@use 'style/index.module.scss' as *;

.productScreenContainer {
  background-color: $off-white;
}

.pdpContainer {
  background-color: $off-white;
  padding-top: 40px;
  position: relative;
  overflow-x: hidden;
  flex-direction: column;
}

.headerContainer,
.bodyContainer,
.sellerSection {
  padding: 0 8px;

  @media only screen and (min-width: $sm) {
    max-width: $maxWidth;
    margin: auto;
    padding: 0;
    justify-content: space-around;
  }
}

.perUnitMargin {
  margin-top: 220px;

  @media only screen and (min-width: $sm) {
    margin-top: 140px;
  }

  @media only screen and (min-width: $md) {
    margin-top: 90px;
  }
}

.lotMargin {
  margin-top: 130px;

  @media only screen and (min-width: $sm) {
    margin-top: 120px;
  }

  @media only screen and (min-width: $md) {
    margin-top: 90px;
  }
}

.sellerSection {
  @media only screen and (min-width: $md) {
    max-width: $maxWidth;
    margin: auto;
    width: 100%;
  }
}

.header {
  @include Header__H1--Mobile;

  @media only screen and (min-width: $md) {
    @include Header__H1--Desktop;
  }
}

.listingNumber {
  @include Subtitle__Subtitle-2--Midnight--Desktop;
  margin-top: 8px;
}

.sellerInfoContainer {
  height: 36px;
  margin: 7px 0 13px;

  @media only screen and (min-width: $md) {
    margin: 48px 0 24px;
  }
}

.soldBy,
.sellerName {
  @include Subtitle__Subtitle-2-Midnight--Mobile;

  @media only screen and (min-width: $md) {
    @include Subtitle__Subtitle-2--Midnight--Desktop;
  }
}

.sellerName {
  font-weight: bold;
}

.sellerImageContainer {
  margin: 0 10px;
  border: solid 1px $white;
  width: 36px;
  height: 36px;
}

.sellerImage {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.conditionRow {
  @include Subtitle__Subtitle-2-Midnight--Mobile;
  display: none;

  @media only screen and (min-width: $md) {
    display: flex;
    margin-left: 120px;
  }
}

.condition {
  font-weight: bold;
}

.seperatorDot {
  color: $metal;
}

.sectionHeader {
  @include Subtitle__Subtitle-1--Leather--Mobile;
  display: flex;
  align-items: center;
  height: 48px;
  border-bottom: solid 2px $midnight-black;

  @media only screen and (min-width: $md) {
    @include Subtitle__Subtitle-1--Leather--Desktop;
  }
}

.availableAmount {
  @include Body__Body-3--Desktop;
  font-style: italic;
  margin: 8px 0 0 16px;

  @media only screen and (min-width: $md) {
    margin-right: 480px;
  }
}

.detailsAndDescription {
  flex-wrap: nowrap;

  @media only screen and (min-width: $md) {
    display: flex;
    flex-direction: column-reverse;
  }
}

.ctaContainer {
  margin: 0 8px 100px;

  @media only screen and (min-width: $sm) {
    margin: 0 auto 100px;
  }

  @media only screen and (min-width: $md) {
    max-width: $maxWidth;
    margin: 0 auto 160px;
  }
}

.imageAndActionContainer {
  height: 224px;
  position: relative;

  @media only screen and (min-width: $md) {
    height: 616px;
    max-width: $maxWidth;
    margin: auto;
    overflow: hidden;
  }
}

.imageSectionWrapper {
  position: relative;
  justify-content: flex-end;
}

.buySectionWrapper {
  position: absolute;
  top: 175px;
  width: 100%;

  @media only screen and (min-width: $sm) {
    top: 200px;
  }

  @media only screen and (min-width: $md) {
    top: 390px;
    right: 10px;
  }
}

.sectionBoxes {
  @media only screen and (min-width: $md) {
    margin-top: 90px;
    justify-content: space-between;
  }
}

.pageDivider {
  height: 4px;
  background-color: $midnight-black;
  width: 100%;
  max-width: $maxWidth;
  margin: 100px 8px 96px;

  @media only screen and (min-width: $sm) {
    margin: 100px auto;
  }

  @media only screen and (min-width: $md) {
    margin: 160px auto 160px;
  }
}
