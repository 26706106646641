@use 'style/index.module.scss' as *;

.buttons {
  display: flex;
  padding-bottom: 16px;
  justify-content: space-between;
  width: 100%;
  max-width: fit-content;

  @media only screen and (min-width: $md) {
    order: 2;
    padding-bottom: 0;
  }
}

.loggedIn.buttons {
  @media only screen and (min-width: $md) {
    padding-left: 30px;
  }
}

.loggedOut.buttons {
  max-width: 185px;
  min-width: max-content;

  @media only screen and (min-width: $sm) {
    max-width: 265px;
  }

  @media only screen and (min-width: $md) {
    max-width: 195px;
  }
}

.buttonWrapper {
  padding: 0;
  margin: 0;

  @media screen and (min-width: $sm) {
    margin: 0 8px;
  }
}

.cartWrapper {
  display: flex;
  justify-content: flex-end;
}

.iconAvatar {
  height: 40px;
  width: 40px;

  @media screen and (min-width: $md) {
    height: 32px;
    width: 32px;
  }
}

.icon {
  width: 23px;
  height: 23px;

  @media screen and (min-width: $md) {
    height: 18px;
    width: 18px;
  }
}

.signupContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: fit-content;

  @media screen and (min-width: $sm) {
    margin-left: 16px;
  }
}

.signup {
  font-size: 0.75rem;
  height: 40px;

  @media screen and (min-width: $md) {
    min-width: max-content;
    width: 160px;
    height: 32px;

    .signInButton {
      @include Button__Icon-Label--Desktop;
      letter-spacing: 1.75px;
      padding: 0 16px;
    }
  }

  @media screen and (max-width: 600px) {
    .signInButton {
      @include Button__Icon-Label--Desktop;
      letter-spacing: 1.75px;
      font-size: 0.75rem;
      padding: 0 8px;
    }
  }
}

.join {
  font-size: 0.75rem;
  font-weight: bold;
  height: 0.875rem;
  margin-top: 5px;
  text-align: center;
}

.joinSell {
  color: $sand-bright-20;
}

.joinBuy {
  color: $midnight-black;
}

.conjoin {
  display: flex;
  margin-right: 16px;

  @media screen and (max-width: $sm) {
    border-radius: 25px;
    background-color: $sand-bright-20;

    .buttonWrapper {
      background-color: unset;
    }
  }

  @media screen and (min-width: $md) {
    margin-right: 45px;

    .buttonWrapper {
      margin: 0 6px;
    }
  }
}
