@use 'style/index.module.scss' as *;

.table {
  padding: 0 8px;
  margin: 0;
  width: 100%;

  @media only screen and (min-width: $md) {
    padding: 0;
  }
}

.tableHeader {
  height: inherit;
  align-items: center;
}

.latestIn {
  min-width: max-content;
}

.subcategory {
  @include Body-1--Mobile;
  font-weight: bold;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: inherit;

  @media only screen and (min-width: $md) {
    @include Body-1--Desktop;
  }
}
