@use 'style/index.module.scss' as *;

.sectionContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.sectionHeader {
  @include Body__Body-2--Bold--Desktop;
  border-bottom: solid 2px $midnight-black;
  height: 48px;
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.sectionButtonsContainer {
  display: flex;
  flex-wrap: wrap;

  @media only screen and (max-width: $md) {
    justify-content: space-between;
  }
}

.iconButton {
  width: 110px;
  height: 100px;
  margin: 20px 0 10px;
}

.iconAvatar {
  height: 56px !important;
  width: 56px !important;
  margin-bottom: 10px;
}

.logout {
  @include Button--Midnight--Desktop;
  text-align: left;
  padding-left: 0;
  margin: 140px 0 80px;
}
