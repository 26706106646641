@use 'style/index.module.scss' as *;

.header {
  @include Body-1--Desktop;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 2px $midnight-black;
  margin: 12px 16px 0;
  padding: 0;
  height: 40px;
}

.content {
  @include Body__Body-2--Desktop;
  padding: 20px 40px 30px 16px;
}

.actionButtons {
  border-top: solid 1px $metal-alpha-30;
  padding: 20px 16px;
}
