@use 'style/index.module.scss' as *;

.wrapper {
  background-color: $off-white;
  padding: 40px 8px 4px;

  @media only screen and (min-width: $md) {
    padding: 90px 8px 96px;
  }

  @media only screen and (min-width: $lg) {
    padding: 90px 0 96px;
  }
}

.maxWidth {
  max-width: $maxWidth;
  margin: auto;

  @media only screen and (min-width: $sm) {
    justify-content: center;
  }

  @media only screen and (min-width: $md) {
    justify-content: unset;
  }
}

.whiteIcon {
  fill: $off-white;
  width: 24px;
}

.logoHeader {
  justify-content: space-between;
  align-items: center;
}

.yardLogo {
  width: 150px;
  cursor: pointer;

  @media only screen and (min-width: $sm) {
    width: 170px;
  }
}

.continueShoppingButton {
  width: max-content;
  height: 42px;
  margin-top: 10px;

  @media only screen and (min-width: $md) {
    margin-top: 20px;
  }
}

.breadcrumbs {
  justify-content: flex-start;

  @media only screen and (min-width: $md) {
    margin-top: 20px;
  }
}

.breadcrumbWrapper {
  @media only screen and (min-width: $sm) {
    margin-left: 43px;
  }

  @media only screen and (min-width: $md) {
    margin-left: 51px;
  }
}

.imageContainer {
  height: 80px;
  width: 80px;
  border-radius: 4px;
  margin-right: 8px;

  @media only screen and (min-width: $md) {
    margin-right: 16px;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 4px;
}

.mainInfoWrapper {
  justify-content: space-between;
}

.yardNumber {
  @include Header__H4-Bold--Desktop;

  @media only screen and (min-width: $md) {
    @include Header__H1--Desktop;
    line-height: 0.7;
  }
}

.nameAndDateContainer {
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: $md) {
    flex-direction: row;
  }
}

.productName {
  @include Subtitle__Subtitle-3-Midnight--Mobile;
  color: $leather;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.orderedOn {
  @include Subtitle__Subtitle-3-Midnight--Mobile;
  margin-top: 2px;

  @media only screen and (min-width: $md) {
    margin-top: 0;
  }
}

.separationDot {
  display: none;

  @media only screen and (min-width: $md) {
    display: block;
    height: 15px;
  }
}

.tracker {
  margin-top: 35px;
  margin-bottom: 8px;

  @media only screen and (min-width: $sm) {
    justify-content: center;
    margin-bottom: 12px;
  }

  @media only screen and (min-width: $md) {
    margin-top: 80px;
    margin-bottom: 0;
  }

  @media only screen and (min-width: $xl) {
    justify-content: flex-end;
  }
}
