@use 'style/index.module.scss' as *;

.formBody {
  padding: 0;
}

.topSectionContainer {
  padding: 0 8px;
}

.sectionContainer {
  border-top: solid 1px $metal-alpha-30;
  padding: 40px 8px 0;
}

.formInput {
  width: 100%;
  margin-bottom: 40px;
}

.over18 {
  height: inherit;
}

.checkboxWrapper {
  width: 100%;
  margin-bottom: 40px;
  position: relative;
  height: 54px;
  display: flex;
}

.invalidCheckbox {
  border: solid 2px $taurus-red-40;
  border-radius: 4px;
}

.over18AssistiveText {
  top: 54px;
}

.passwordStrengthBar,
.passwordStrengthBar p {
  text-align: left !important;
  font-weight: bold;
}

.passwordRequirementsContainer {
  @include Body__Body-2--Desktop;
  margin-top: 10px;
}

.passwordRequirements {
  margin-top: 5px;
}

.passwordRequirement {
  margin-bottom: 0;
}

.visibilityWrapper {
  position: relative;
  width: 100%;

  .visibilityIcon {
    position: absolute;
    top: 19px;
    right: 15px;
    font-size: 20px;
    opacity: 0.5;
    cursor: pointer;
  }
}
