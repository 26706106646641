@use 'style/index.module.scss' as *;

.input {
  margin-bottom: 48px;
}

.lotText {
  @include Body__Body-3--Desktop;
  font-style: italic;
  margin: 10px 0 45px;
  padding-left: 5px;
  cursor: default;
}

.priceRow {
  margin-bottom: 8px;
}

.boldPrice {
  @include Subtitle__Subtitle-2-Midnight--Mobile;
  font-weight: bold;
  margin-right: 8px;

  @media only screen and (min-width: $sm) {
    @include Subtitle__Subtitle-2--Midnight--Desktop;
  }
}

.disclaimer {
  @include Specialized__Caption-Metal--Desktop;
}
