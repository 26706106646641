@use './index.module.scss' as *;

body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  background-color: $off-white-material;
  font-family: Roboto;
}

.cursor-pointer {
  cursor: pointer;
}

.default-cursor {
  cursor: default;
}

::placeholder {
  @include Subtitle__Subtitle-2-Midnight--Mobile;
}

textarea {
  outline: none;
}
