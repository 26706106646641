@use 'style/index.module.scss' as *;

.side-menu-modal {
  background-color: $white;
  width: 100vw;
  height: 100vh;
}

.side-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(6px);
}

.right-side-menu {
  position: absolute;
  right: 0;
}

.side-menu-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.side-menu__top-button-container {
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px $metal;
  padding: 16px 8px;
}

.side-menu__content {
  flex-grow: 1;
  overflow-y: auto;
}

/* desktop styling */
@media only screen and (min-width: $desktop) {
  .side-menu-modal {
    width: 40vw;
  }
}
