@use 'style/index.module.scss' as *;

.ctaContainer {
  background-color: inherit;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 16px 16px 30px;
  border-left: solid 1px $metal;
  border-right: solid 1px $metal;
  position: relative;

  @media only screen and (min-width: $md) {
    flex-direction: row;
    border: none;
    padding: 0;
  }
}

.yardLogoContainer {
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-end;

  @media only screen and (min-width: $md) {
    margin-bottom: 0;
    align-items: center;
    position: relative;
  }
}

.yardLogo {
  width: 71px;
  height: 18px;

  @media only screen and (min-width: $md) {
    width: 75px;
    height: 25px;
    transform: rotate(90deg);
    margin-bottom: 15px;
    position: absolute;
    right: 48px;
  }
}

.headerText {
  @include Header__H3--Desktop;
  line-height: 1;

  @media only screen and (min-width: $md) {
    @include Header__H2--Desktop;
  }
}

.boldHeaderText {
  @include Header__H1--Mobile;
  line-height: 1;

  @media only screen and (min-width: $md) {
    @include Header__H1--Desktop;
  }
}

.subText {
  @include Body__Body-1-Left--Mobile;
  margin-top: 24px;

  @media only screen and (min-width: $md) {
    @include Body-1--Desktop;
    margin-top: 60px;
  }
}

.textContainer {
  @media only screen and (min-width: $md) {
    border: solid 1px $metal;
    padding: 110px 128px 60px 20px;
  }
}

.buttonContainer {
  margin-top: 20px;
  padding: 48px 48px 64px;
  background-image: url('../../assets/images/dot_background-pattern1.png');

  @media only screen and (min-width: $md) {
    margin-top: 0;
    margin-left: 16px;
    display: flex;
    align-items: center;
  }
}

.button {
  width: 100%;
}

.logoHorizontalLine,
.logoVerticalLine {
  display: none;

  @media only screen and (min-width: $md) {
    display: unset;
    position: absolute;
  }
}

.logoHorizontalLine {
  @media only screen and (min-width: $md) {
    border-top: solid 1px $metal;
    width: 73px;
    right: 0;
  }
}

.logoVerticalLine {
  @media only screen and (min-width: $md) {
    border-right: solid 1px $metal;
    height: 8px;
    right: 73px;
  }
}

.border {
  position: absolute;
  background: $metal;
  height: 1px;
  width: 15px;

  @media only screen and (min-width: $md) {
    display: none;
  }
}

.topBorder {
  top: 0;
}

.bottomBorder {
  bottom: 0;
}

.leftBorder {
  left: 0;
}

.rightBorder {
  right: 0;
}
