@use 'style/index.module.scss' as *;

.wrapper {
  padding-top: 10px;
}

.ctaItemWrapper {
  margin: 45px 8px;

  @media only screen and (min-width: $sm) {
    padding: 0 16px;
    margin-bottom: 25px;
  }

  @media only screen and (min-width: $md) {
    max-width: $maxWidth;
    padding: 0;
    margin: 0 auto 95px;
  }
}

.linksContainer {
  margin-left: 8px;
  max-width: $maxWidth;

  @media only screen and (min-width: $md) {
    margin: 0 auto;
  }
}

.linksWrapper {
  @media only screen and (min-width: $sm) {
    display: flex;
    flex-direction: row;
    margin-left: 16px;
  }

  @media only screen and (min-width: $md) {
    margin-left: 0;
  }
}

.link {
  @include Specialized__Table__Left--Bold--Desktop;
  letter-spacing: 1.25px;
  line-height: 2;
}
