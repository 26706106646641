@use 'style/index.module.scss' as *;

.orderContainer {
  border: solid 1px $off-white;
  background-color: $white;
  margin-bottom: 16px;
  height: 80px;

  &:hover {
    border: none;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14);
  }
}

.draftContainer,
.draftContainer .productName,
.draftContainer .listingDate {
  color: $metal;
}

.topRow {
  padding: 8px 8px 8px 6.7px;
  height: 55px;

  @media only screen and (min-width: $sm) {
    padding: 8px;
  }
}

.iconGreyBackground {
  border: solid 2px $off-white;
  background-color: $metal;
}

.iconSandBackground {
  background-color: $sand;
}

.iconBlackBackground {
  background-color: $midnight-black;
}

.iconWhiteBackground {
  border: solid 2px $off-white;
  background-color: $white;
}

.draftIconContainer {
  border: solid 2px $off-white;
  background-color: $metal-bright-50;
}

.disputeIconContainer {
  border: solid 2px $off-white;
  background-color: $taurus-red-40;
}

.iconContainer {
  width: 27px;
  height: 27px;
  border-radius: 27px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width: $sm) {
    width: 36px;
    height: 36px;
    border-radius: 36px;
  }
}

.iconWithStepIndicator {
  position: relative;
  height: 27px;
  width: 27px;

  .iconContainer {
    position: absolute;
    width: 22px;
    height: 22px;
  }

  .iconWithIndicator {
    top: 3px;
    right: 1.8px;
  }

  @media only screen and (min-width: $sm) {
    height: 40px;
    width: 40px;

    .iconContainer {
      top: 3px;
      width: 24px;
      height: 24px;
    }

    .iconWithIndicator {
      padding: 4px;
      top: 4px;
      right: 4px;
    }
  }
}

.stepIndicatorIcon {
  width: inherit;
  height: inherit;
}

.topLeft,
.topRight,
.bottomLeft {
  display: flex;
  align-items: center;

  @media only screen and (min-width: $sm) {
    display: flex;
    align-items: center;
  }
}

.topLeft {
  max-width: 65%;

  @media only screen and (min-width: $sm) {
    max-width: 55%;
  }
}

.productNameContainer {
  display: flex;
  flex-direction: column;
  margin-left: 6px;
  overflow: hidden;

  @media only screen and (min-width: $sm) {
    flex-direction: row;
  }
}

.productName {
  @include Subtitle__Subtitle-3--midnight--Desktop;
  height: 22px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media only screen and (min-width: $sm) {
    flex-direction: row;
  }
}

.orderNumber {
  @include Body__Body-3--Desktop;
  color: $leather;
  height: 16px;
  margin-bottom: 1px;
  min-width: fit-content;
  cursor: pointer;

  @media only screen and (min-width: $sm) {
    @include Subtitle__Subtitle-3--midnight--Desktop;
    color: $leather;
    margin: 0 16px 0 13px;
  }
}

.topRight {
  flex-direction: column;
  align-items: flex-end;

  @media only screen and (min-width: $sm) {
    width: 40%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.sellerInfoContainer {
  display: flex;
  height: 16px;
  align-items: baseline;
}

.sellerName,
.sellerRating {
  @include Body__Body-3--Desktop;
  text-align: right;
  color: $metal;
  height: 16px;
  margin-bottom: 1px;

  @media only screen and (min-width: $sm) {
    @include Body__Body-2--Desktop;
  }
}

.sellerRating {
  font-weight: bold;
  display: flex;
  align-items: flex-start;
  margin-left: 4px;

  @media only screen and (min-width: $sm) {
    align-items: center;
    margin-left: 8px;
  }
}

.starIcon {
  width: 16px;
  height: 17px;
  transform: translate(-1px, -2px);
  margin-left: 1px;
  fill: $metal;

  @media only screen and (min-width: $sm) {
    height: 16px;
    transform: translate(0, -1px);
    fill: $midnight-black;
  }
}

.listingPrice {
  @include Subtitle__Subtitle-3-Midnight--Mobile;
  display: flex;
  align-items: center;
  height: 22px;

  @media only screen and (min-width: $sm) {
    @include Subtitle__Subtitle-3--midnight--Desktop;
  }
}

.lineSeperator {
  height: 1px;
  opacity: 0.2;
  background-color: $metal;
}

.bottomRow {
  display: flex;
  justify-content: space-between;
  padding-right: 8px;
  height: 24px;
  background-color: $off-white-material;
}

.orderStatus {
  @include Breadcrumb__Breadcrumb--Active--Desktop;
  padding: 3px 16px;
  display: flex;
  align-items: center;
  height: 18px;
  background-color: $white;
}

.processingStatus,
.orderedStatus,
.rate-orderStatus,
.in-transitStatus,
.buyer-inspectingStatus,
.awaiting-pickup-sellerStatus {
  background-color: $sand;
  color: $white;
}

.ship-nowStatus,
.awaiting-pickup-buyerStatus,
.confirm-deliveryStatus,
.confirm-pickupStatus,
.inspect-materialsStatus {
  background-color: $midnight-black;
  color: $white;
}

.completeStatus {
  background-color: $white;
  color: $sand-20;
}

.archivedStatus {
  background-color: $off-white-material;
  color: $metal;
}

.disputedStatus {
  background-color: $taurus-red-40;
  color: $white;
}

.draftStatus {
  background-color: $metal-alpha-30;
  color: $white;
}

.listingDate {
  @include Body__Body-2--Desktop;
  margin-left: 8px;
}

.whiteIcon {
  fill: $white;
}

.offWhiteIcon {
  fill: $off-white;
}

.metalIcon {
  fill: $metal;
}

.sandIcon {
  fill: $sand;
}

.iconSize {
  width: 18px;
  height: 18px;

  @media only screen and (min-width: $sm) {
    width: 24px;
    height: 24px;
  }
}

.stepIndicatorSize {
  width: 28px;
  height: 28px;

  @media only screen and (min-width: $sm) {
    width: 40px;
    height: 40px;
  }
}
