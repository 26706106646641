@use 'style/index.module.scss' as *;

.button {
  @include Button__Icon-Label--Desktop;
  cursor: pointer;
  border: none;
  background-color: inherit;

  &:disabled {
    color: $metal-alpha-30;
    pointer-events: none;

    .label {
      color: $metal-alpha-30;
      pointer-events: none;
    }

    .filledAvatar {
      background-color: $metal-alpha-30 !important;
    }
  }
}

.label {
  @include Button__Icon-Label--Desktop;
  cursor: pointer;
}

.blackLabel {
  color: $midnight-black;
}

.sandLabel {
  color: $sand-bright-20;
}

.rightLabel {
  display: flex;
  align-items: center;

  .label {
    margin-left: 5px;
  }
}

.bottomLabel {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.avatar {
  background-color: inherit !important;
}

.avatarWithLabel {
  margin-bottom: 4px;
}

.squareAvatar {
  border-radius: unset !important;
}

.filledAvatar {
  background-color: $sand-bright-20 !important;
}

.borderAvatar {
  border: solid 1px $sand;
}

.shadowOutline {
  @include Box-Shadow;
}
