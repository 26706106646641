@use 'style/index.module.scss' as *;

.container {
  margin-bottom: 16px;
  border: solid 1px $off-white;
  background-color: $white;
  height: 80px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.14);

  &:hover {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14);
  }

  @media only screen and (min-width: $md) {
    box-shadow: none;
  }
}

.imageWrapper {
  width: 96px;
  height: 79px;
  overflow: hidden;
  cursor: pointer;
}

.image {
  width: 96px;
  height: 80px;
  object-fit: cover;
}

.rightSide {
  width: calc(100% - 96px);
}

.topInfo {
  padding: 8px 8px 0;
  height: 53px;
  cursor: pointer;
}

.subCategory {
  @include Body__Body-3--Desktop;
  color: $metal;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 1rem;
  text-transform: capitalize;
}

.priceRow {
  height: 24px;
}

.name {
  @include Body__Body-2--Desktop;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 50%;

  @media only screen and (min-width: $sm) {
    max-width: 70%;
  }

  @media only screen and (min-width: $md) {
    @include Subtitle__Subtitle-3--midnight--Desktop;
  }
}

.price {
  @include Body__Body-2--Desktop;
  font-weight: bold;
  text-align: right;
  color: $sand-20;

  @media only screen and (min-width: $md) {
    @include Subtitle__Subtitle-3--midnight--Desktop;
    color: $sand-20;
  }
}

.lineSeparator {
  height: 1px;
  opacity: 0.2;
  background-color: $metal;
  width: 100%;
}

.bottomInfo {
  background-color: $off-white-material;
  height: 24px;
}

.bottomLeft {
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 8px;
}

.sellerRatingContainer {
  display: flex;
  align-items: center;
  margin-right: 16px;
  height: 100%;
}

.seller {
  @include Body__Body-2--Desktop;
  color: $metal;
}

.sellerRating {
  @include Body__Body-2--Bold--Desktop;
  line-height: normal;
  margin: 0 2px;
}

.starIcon {
  width: 18px;
  height: 18px;
}

.condition {
  @include Body__Body-2--Desktop;
  text-transform: capitalize;
}

.iconMenu {
  position: relative;
  padding-right: 8px;
}

.icon {
  cursor: pointer;
}

.showMenu {
  display: flex;
}

.hideMenu {
  display: none;
}

.menu {
  right: 0;
  width: 180px;
  position: absolute;
  top: 8px;
  z-index: 1;
  flex-direction: column;
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14);

  @media only screen and (min-width: $sm) {
    top: 10px;
  }
}

.option {
  @include Body__Body-2--Desktop;
  height: 40px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  text-decoration: none;

  &:hover {
    background-color: $midnight-text-rollover;
  }

  &:focus {
    background-color: $midnight-text-click;
  }
}

.firstOption {
  border-radius: 8px 8px 0 0;
}

.lastOption {
  border-radius: 0 0 8px 8px;
}

.option.disabled {
  color: $metal;
  cursor: default;
  background-color: $off-white-material;
}
