@use 'style/index.module.scss' as *;

.dropdownWrapper {
  padding: 16px 16px 25px;
}

.backgroundColor {
  background-color: $white;
}

.dropdownWrapperBorderRadius {
  border-radius: 8px 8px 0 0;
}

.inputsWrapper {
  padding: 0 16px 48px;
  margin-top: 16px;
}

.formDisabled {
  background-color: $off-white-material;
}

.addressTypeSection {
  border-top: solid 1px $metal-alpha-30;
  padding-top: 15px;
}

.headerText {
  @include Body__Body-2--Bold--Desktop;
  text-transform: capitalize;

  @media only screen and (min-width: $sm) {
    margin-bottom: 15px;
  }
}

.bottomButtonsWrapper {
  display: flex;
  justify-content: space-between;
  padding: 20px 16px 0;
  border-top: solid 1px $metal-alpha-30;
}

.saveButton {
  width: 200px;
}

.cancelButton {
  width: 140px;
}
