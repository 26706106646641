@use 'style/index.module.scss' as *;

.wrapper {
  max-width: $maxWidth;
  padding: 0 8px 110px;

  @media only screen and (min-width: $md) {
    padding: 0;
    margin: 0 auto 110px;
  }
}

.backButtonLink {
  justify-content: flex-start;
  margin-bottom: 25px;
}
