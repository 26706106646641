@use 'style/index.module.scss' as *;

.wrapper {
  padding: 8px;
  margin-bottom: 16px;
  border: solid 1px $metal;
  border-radius: 4px;
}

.row {
  margin-bottom: 0;

  @media only screen and (min-width: $sm) {
    margin-bottom: 48px;
  }
}

.input {
  margin-bottom: 48px;

  @media only screen and (min-width: $sm) {
    margin-bottom: 0;
  }
}

.inputRow {
  margin-bottom: 48px;
}

.stackableWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width: $sm) {
    justify-content: flex-start;
  }
}

.dimensionsText {
  @include Specialized__Caption--Desktop;
  margin-bottom: 12px;
}

.deletePackageButton {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
}

.closeIcon {
  cursor: pointer;
}

.hazardousNotAllowed {
  @include Body__Body-2--Bold--Desktop;
  margin-top: 12px;
  padding: 0 8px;
}
