@use 'style/index.module.scss' as *;

.boldText {
  font-weight: bold;
}

.underlinedText {
  text-decoration: underline;
}

.italicizedText {
  font-style: italic;
}
