@use 'style/index.module.scss' as *;

.header {
  @include Header__H1--Mobile;

  @media only screen and (min-width: $desktop) {
    @include Header__H1--Desktop;
  }
}

.button {
  width: 100%;
  margin-top: 40px;
}
