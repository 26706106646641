@use 'style/index.module.scss' as *;

.wrapper {
  padding-bottom: 110px;
}

.headerContainer {
  background-color: $off-white;
  padding: 16px 8px 24px;

  @media only screen and (min-width: $md) {
    padding: 16px 0 45px;
  }
}

.header {
  @include Header__H1--Mobile;
  align-items: flex-end;

  @media only screen and (min-width: $md) {
    @include Header__H1--Desktop;
    max-width: $maxWidth;
    margin: auto;
  }
}

.headerWrapper {
  justify-content: space-around;
  max-width: $maxWidth;
  margin: auto;
}

.cartIcon {
  margin-left: 5px;
  height: 50%;

  @media only screen and (min-width: $md) {
    height: 50px;
  }
}

.bodyContainer {
  padding: 16px 8px;

  @media only screen and (min-width: $md) {
    max-width: $maxWidth;
    margin: auto;
    padding: 16px 0;
    justify-content: center;
  }
}

.bodyHeader {
  @include Subtitle__Subtitle-2-Midnight--Mobile;
  padding: 0 8px 16px;
  text-align: center;

  @media only screen and (min-width: $md) {
    @include Subtitle__Subtitle-2--Midnight--Desktop;
    padding: 0 0 16px;
  }
}

.content {
  margin: auto;
  padding-top: 20px;
  border-top: solid 4px $midnight-black;

  @media only screen and (min-width: $sm) {
    margin: 0 16px;
    justify-content: space-between;
  }

  @media only screen and (min-width: $md) {
    margin: 0;
    justify-content: center;
  }
}

.emptyCartMessage {
  @include Body-1--Desktop;
  text-align: center;
  margin-top: 25px;
}

.searchText {
  @include Body__Body-1-Left--Mobile;
  text-align: center;
  padding-top: 15px;
}

.searchSectionWrapper {
  margin-top: 24px;
  height: 120px;

  @media only screen and (min-width: $md) {
    height: 140px;
  }
}
