@use 'style/index.module.scss' as *;

.title {
  @include Header__H1--Mobile;
  text-transform: capitalize;

  @media only screen and (min-width: $md) {
    @include Header__H1--Desktop;
  }
}

.body1 {
  @include Body-1--Mobile;

  @media only screen and (min-width: $md) {
    @include Body-1--Desktop;
  }
}

.body2 {
  color: $midnight-black;
  font-size: 0.875rem;
  margin: 0;
}

.bold {
  font-weight: bold;
}

.h2 {
  @include Header__H4-Normal--Mobile;

  @media only screen and (min-width: $md) {
    @include Header__H2--Desktop;
    font-weight: unset;
  }
}

.h3 {
  @include Header__H3--Desktop;
}

.h4 {
  @include Header__H4-Normal--Mobile;

  @media only screen and (min-width: $md) {
    @include Header__H4-Normal--Desktop;
  }
}

.specializedCaption {
  @include Specialized__Caption--Desktop;
}
