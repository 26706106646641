@use 'style/index.module.scss' as *;

.wrapper {
  padding: 0 8px;

  @media only screen and (min-width: $sm) {
    padding: 0 16px;
  }

  @media only screen and (min-width: $sm) {
    justify-content: center;
    max-width: $maxWidth;
    margin: auto;
    padding: 0;
  }
}

.header {
  font-size: 20px;
  padding: 30px 0 20px;
  text-align: center;

  @media only screen and (min-width: $sm) {
    text-align: left;
  }
}

.categoriesWrapper {
  @media only screen and (min-width: $sm) {
    justify-content: space-between;
  }
}

.itemWrapper {
  cursor: pointer;
  flex-wrap: nowrap;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12),
    0 1px 1px 0 rgba(0, 0, 0, 0.14);
  background-color: $off-white-material;
  height: 185px;
  max-height: 185px;
  max-width: 190px;

  &:hover {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14);
  }
}

.imageWrapper {
  width: 100%;
  min-height: 70%;
  height: 70%;
  max-height: 70%;
}

.image {
  width: inherit;
  height: 100%;
  object-fit: cover;
  border-radius: 4px 4px 0 0;
}

.nameWrapper {
  display: flex;
  align-items: center;
  background-color: $white;
  border-radius: 0 0 4px 4px;
}

.categoryName {
  @include Subtitle__Subtitle-2--Midnight--Desktop;
  padding: 0 8px;
}

.comingSoon {
  cursor: not-allowed;
  background-color: rgba(234, 234, 234, 0.7);
  position: relative;

  &:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
      0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  }

  .image {
    mix-blend-mode: luminosity;
    opacity: 0.3;
  }

  .categoryName {
    color: $metal;
  }
}

.comingSoonBadgeWrapper {
  position: absolute;
  height: 70%;
}

.comingSoonBadge {
  @include Subtitle__Subtitle-3--midnight--Desktop;
  width: fit-content;
  padding: 4px 6px;
  border-radius: 15px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.14);
  background-color: $white;
}
