@use 'style/index.module.scss' as *;

.emailVerifiedModal {
  background-color: $off-white-material;
}

.modalButtons,
.headerWrapper {
  background-color: $sand;
}

.modalButtons {
  @media only screen and (min-width: $sm) {
    padding: 32px 32px 0;
  }

  @media only screen and (min-width: $md) {
    padding: 32px 100px 0 !important;
  }
}

.container {
  @media only screen and (min-width: $sm) {
    flex-direction: column;
    align-items: center;
  }
}

.headerWrapper {
  padding: 0 16px;

  @media only screen and (min-width: $md) {
    padding: 0;
  }
}

.headerContainer {
  padding: 50px 8px;

  @media only screen and (min-width: $md) {
    max-width: $maxWidth;
    margin: auto;
    padding: 30px 0;
  }
}

.header {
  @include Header__H1--Mobile;
  background-color: $sand;
  color: $white;

  @media only screen and (min-width: $md) {
    @include Header__H1--Desktop;
    color: $white;
  }
}

.formContainer {
  padding-bottom: 0;
}

.formBody {
  padding: 0;
}

.bodyContainer {
  padding: 16px 8px 0;

  @media only screen and (min-width: $sm) {
    margin: 32px auto 0;
    padding: 0;
  }
}

.topSectionWrapper {
  margin: 0 8px 25px;

  @media only screen and (min-width: $desktop) {
    margin: 0 0 25px;
  }
}

.readyToCheckout {
  @include Subtitle__Subtitle-1--Leather--Mobile;
  margin-bottom: 32px;

  @media only screen and (min-width: $sm) {
    @include Subtitle__Subtitle-1--Leather--Mobile;
  }
}

.newsletterCheckbox {
  @include Body-1--Desktop;
  font-weight: normal;

  @media only screen and (min-width: $desktop) {
    @include Body-1--Desktop;
    font-weight: normal;
  }
}

.middleTextContainer {
  margin: 8px;
}

.emailVerifiedMiddleText {
  @include Body__Body-2--Desktop;
  margin: 10px 0;
}

.profileHeader {
  @include Specialized__Profile-header;
  margin: 16px 0px 0 24px;
  padding-top: 10px;
}

.exampleProfileWrapper {
  display: flex;
  align-items: center;
  height: 56px;
  margin: 0 24px;
  background-color: $off-white-material;
  padding: 15px 0 15px 8px;
}

.businessLogo {
  width: 54px;
  height: 54px;
  margin-right: 8px;
  object-fit: cover;
  border-radius: 50%;
  border: solid 2px $white;
}

.emailVerifiedBusinessNameContainer {
  display: flex;
  flex-direction: column;
}

.soldBy {
  @include Header__H4-Normal--Mobile;
  font-weight: normal;
}

.emailVerifiedBusinessName {
  @include Header__H4-Normal--Mobile;
  font-weight: bold;
}

.emailVerifiedBusinessLocation {
  @include Body__Body-2--Bold--Desktop;
}

.emailVerifiedBusinessHistory {
  @include Body__Body-2--Desktop;
}

.formButtonContainer {
  margin-top: 14px;
  border-top: solid 1px $metal-alpha-30;
  padding: 16px 8px;
}

.emailVerifiedBusinessButtonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (min-width: $desktop) {
    justify-content: space-between;
  }
}

.myAccount {
  @include Button--Midnight--Desktop;
}

.footer {
  padding: 0;
  margin: 100px 8px 80px;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: $desktop) {
    flex-direction: column;
    margin: 100px auto 32px;
    justify-content: center;
    align-items: center;
  }
}

.bottomLink {
  @include Button--Midnight--Desktop;
  height: 56px;
  margin-bottom: 16px;
}
