@use 'style/index.module.scss' as *;

.sortByMenu {
  margin-left: 25px;

  @media only screen and (min-width: $desktop) {
    margin-left: 0;
    background-color: $container-state-off-white-alpha-60;
    padding: 8px;
  }
}
