@use 'style/index.module.scss' as *;

.signinModal {
  background-color: $off-white-material;
}

.modalButtonContainer {
  @media only screen and (min-width: $md) {
    padding: 24px 0 0;
  }
}

.signinModalContentWrapper {
  @media only screen and (min-width: $md) {
    max-width: $maxWidth;
    margin: auto;
    padding-bottom: 100px;
  }
}

.wrapper {
  padding: 0 8px;
  max-width: $maxWidth;
  margin: auto;

  @media only screen and (min-width: $sm) {
    padding: 0 16px;
  }

  @media only screen and (min-width: $md) {
    padding: 0;
  }
}

.headerContainer {
  text-align: center;
  margin-top: 30px;

  @media only screen and (min-width: $sm) {
    margin-top: 45px;
  }
}

.logo {
  margin: auto;
  width: 175px;
  height: 50px;
}

.header {
  @include Header__H4-Bold--Desktop;
  margin-top: 15px;
}

.bodyWrapper {
  display: flex;
  flex-direction: column;
  max-width: 550px;
  margin: auto;
}

.formContainer {
  margin: 24px 0 80px;
  padding-bottom: 40px;
}

.formBody {
  display: flex;
  flex-direction: column;
}

.input {
  margin-bottom: 40px;
}

.forgotPasswordLink {
  text-align: center;
  margin-top: 40px;
}

.button {
  width: 100%;
}

.footer {
  @include Specialized__Legal;
  padding: 0 45px;
  margin-top: 60px;
}

.legalLink {
  @include Specialized__Legal;
  padding: 0;
  letter-spacing: normal;
  color: $leather;
}
