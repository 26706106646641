@use 'style/index.module.scss' as *;

.headerContainer {
  height: 48px;
  justify-content: space-between;
  align-items: flex-start;
}

.statusContainer {
  @include Breadcrumb__Breadcrumb--Active--Desktop;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.pendingState {
  .header {
    @include Subtitle__Subtitle-1--Metal--Desktop;
    line-height: 1;
  }

  .statusContainer {
    color: $metal-bright-50;
  }

  .bodyContainer {
    border: solid 1px $metal-alpha-30;
    background-color: $off-white-material;
  }
}

.focusState {
  .header {
    @include Subtitle__Subtitle-1--Midnight--Desktop;
    line-height: 1;
  }

  .bodyContainer {
    background-color: $white;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14);
  }
}

.actionNeeded {
  .header {
    @include Subtitle__Subtitle-1--Midnight--Bold--Desktop;
    line-height: 1;
  }

  .bodyContainer {
    border-top: solid 2px $sand-20;
    background-color: $white;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 5px 5px -3px rgba(199, 151, 105, 0.2),
      0 3px 14px 2px rgba(199, 151, 105, 0.22),
      0 8px 10px 1px rgba(199, 151, 105, 0.19);
  }
}

.completeState {
  .header {
    @include Subtitle__Subtitle-1--Sand--Normal--Desktop;
  }

  .statusContainer {
    color: $sand-20;
  }

  .bodyContainer {
    border-top: solid 2px $sand-20;
    border-left: solid 1px $metal-alpha-30;
    border-right: solid 1px $metal-alpha-30;
    border-bottom: solid 1px $metal-alpha-30;
    background-color: $off-white-material;
  }
}

.redStatus {
  color: $taurus-red-40;
}
