@use 'style/index.module.scss' as *;

.textLink {
  @include Button--Midnight--Desktop;
  border: none;
  background-color: inherit;
  padding: 0 16px;
  text-decoration: none;

  &:disabled {
    color: $metal-alpha-30;
    pointer-events: none;
  }
}

.sand20 {
  color: $sand-20;

  &:hover {
    color: $sand-20-rollover;
  }

  &:focus {
    color: $sand-20-click;
  }
}

.sand {
  color: $sand;

  &:visited {
    color: $sand;
  }

  &:hover {
    color: $sand-rollover;
  }

  &:focus {
    color: $sand-click;
  }
}

.black {
  color: $midnight-black;

  &:hover {
    color: $midnight-rollover;
  }

  &:focus {
    color: $midnight-click;
  }
}

.leather {
  color: $leather;

  &:hover {
    color: $leather-rollover;
  }

  &:focus {
    color: $leather-click;
  }
}

.brightSand20 {
  color: $sand-bright-20;

  &:hover {
    color: $sand-bright-rollover;
  }

  &:focus {
    color: $sand-bright-click;
  }
}

.blue {
  color: $escrow-blue;
}

.white {
  background-color: transparent;
  color: $white;
}
