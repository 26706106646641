@use 'style/index.module.scss' as *;

.title {
  @include Subtitle__Subtitle-3-Midnight--Mobile;
  font-weight: bold;
  margin-bottom: 5px;
}

.section {
  margin-top: 5px;
  margin-bottom: 30px;
}

.info {
  @include Body__Body-2--Desktop;
  line-height: 1.4;
}

.contactUsButtonWrapper {
  margin-top: 10px;
}

.emailLinks {
  a {
    color: $leather;
    text-decoration: none;
  }

  a:hover {
    color: $sand-20;
  }
}
