@use 'style/index.module.scss' as *;

.container {
  width: fit-content;
  height: 32px;
  margin-right: 8px;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  border: 1px solid $metal-alpha-30;
  border-radius: 8px;
}

.closeIcon {
  margin-left: 8px;
}
