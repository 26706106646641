@use 'style/index.module.scss' as *;

.wrapper {
  display: flex;
  cursor: pointer;
}

.imageWrapper {
  height: 80px;
  width: 80px;
  border-radius: 4px;
}

.image {
  height: inherit;
  width: inherit;
  border-radius: inherit;
}

.name {
  @include Subtitle__Subtitle-1--Leather--Mobile;
  display: flex;
  align-items: center;
  text-decoration: underline;
  text-transform: capitalize;
  margin-left: 8px;
  line-height: 1.2;
  width: 75%;

  @media only screen and (min-width: $sm) {
    @include Subtitle__Subtitle-1--Leather--Desktop;
    margin-left: 16px;
    line-height: 1.2;
    width: 50%;
  }
}
