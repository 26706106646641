@use 'style/index.module.scss' as *;

.shippingForm {
  padding: 0;
}

.shippingFormTitle {
  margin-bottom: 0;
}

.formWrapper {
  margin-bottom: 40px;
}

.addPackageButtonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.addPackageButton {
  display: flex;
  align-items: center;
}
