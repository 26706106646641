@use 'style/index.module.scss' as *;

.detailsContainer {
  margin-top: 40px;

  @media only screen and (min-width: $md) {
    margin-top: 90px;
  }
}

.detailRow {
  height: 48px;
}

.detailName {
  @include Specialized__Table__Left--Regular--Desktop;
}

.detail {
  @include Specialized__Table__Right--Desktop;
}

.divider {
  height: 1px;
  width: 100%;
  opacity: 0.5;
  background-color: $metal;
}
