@use 'style/index.module.scss' as *;

.wrapper {
  width: 100%;
  margin-top: 16px;
  border-radius: 8px;
  align-items: center;
  padding: 16px;

  @media only screen and (min-width: $md) {
    min-height: 88px;
    height: max-content;
    justify-content: space-around;
  }
}

.transparent {
  background-color: transparent;
  border: solid 1px $metal-alpha-30;
}

.black {
  background-color: $midnight-black;
  box-shadow: 0 5px 5px -3px rgba(199, 151, 105, 0.2),
    0 3px 14px 2px rgba(199, 151, 105, 0.22),
    0 8px 10px 1px rgba(199, 151, 105, 0.19);

  .textWrapper {
    color: $off-white;
  }

  .boldText {
    color: $white;
  }
}

.textWrapper {
  @include Body__Body-2--Desktop;
  line-height: 1.5;
  flex-direction: column;
  cursor: default;

  @media only screen and (min-width: $md) {
    flex-direction: row;
  }
}

.boldText {
  font-weight: bold;
  margin-bottom: 6px;

  @media only screen and (min-width: $md) {
    margin-bottom: 0;
  }
}

.buttonWrapper {
  justify-content: flex-end;
  align-items: center;
  flex-direction: column-reverse;
  margin-top: 16px;

  @media only screen and (min-width: $md) {
    flex-direction: row;
    margin-top: 0;
  }
}

.underlineLink {
  text-decoration: underline;
}

.button {
  width: 100%;
  margin-bottom: 16px;

  @media only screen and (min-width: $md) {
    width: revert;
    margin-bottom: 0;
  }
}

.ctaButton {
  border: solid 2px $white;
}
