@use 'style/index.module.scss' as *;

.materialsContainer {
  margin-top: 60px;

  @media only screen and (min-width: $desktop) {
    margin: 0 0 150px;
  }
}

.documentRow {
  height: 80px;
  border-bottom: solid 1px $metal;
}

.removeATagCss {
  color: inherit;
  text-decoration: none;
}

.documentLeftSide {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.documentIcon {
  height: 38px;
  margin-right: 5px;
}

.sellerName {
  @include Body__Body-3--Desktop;
  color: $metal;
}

.documentName {
  font-size: 18px;
  color: $midnight-blackier;
}
