@use 'style/index.module.scss' as *;

.buttonsContainer {
  border-top: solid 1px $metal-alpha-30;
  padding: 16px;
}

.whatIsThisLink {
  padding-left: 0;
}

.button {
  width: 100%;
}
