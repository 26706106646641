@use 'style/index.module.scss' as *;

.topSection {
  background-color: $off-white;
  margin: auto;

  @media only screen and (min-width: $sm) {
    padding: 34px 0 10px;
  }

  @media only screen and (min-width: $md) {
    padding: 34px 0 98px;
  }
}

.headerWrapper {
  padding: 8px;

  @media only screen and (min-width: $md) {
    max-width: $maxWidth;
    justify-content: space-between;
    padding: 0;
    margin: auto;
  }
}

.innerHeaderWrapper {
  @media only screen and (min-width: $sm) {
    margin: auto;
    flex-direction: column;
  }

  @media only screen and (min-width: $md) {
    justify-content: space-between;
    flex-direction: row;
  }
}

.leftSideHeader {
  display: flex;
  flex-direction: column;
  cursor: default;
}

.header {
  line-height: 1;
  margin: 0 0 16px;

  @media only screen and (min-width: $md) {
    line-height: 1;
    margin: 0 0 12px;
  }
}

.businessName {
  @include Specialized__Table__Left--Link-Desktop;
  font-weight: bold;
}

.rightSideHeader {
  flex-direction: column-reverse;
  align-items: center;
  margin: 24px auto 0;

  @media only screen and (min-width: $md) {
    flex-direction: row;
    justify-content: flex-end;
    margin: 26px 0 0;
  }
}

.headerButtons {
  width: 100%;
  background-color: transparent;

  @media only screen and (min-width: $md) {
    width: unset;
  }
}

.howToSell {
  margin-top: 8px;

  @media only screen and (min-width: $md) {
    margin-top: 0;
    margin-right: 8px;
  }
}

.tableWrapper {
  padding: 100px 0;

  @media only screen and (min-width: $md) {
    max-width: $maxWidth;
    margin: auto;
    padding-top: 75px;
  }
}

.ctaWrapper {
  background-color: $off-white;
  padding: 0 8px;

  @media only screen and (min-width: $md) {
    padding: 0;
  }
}

.ctaContainer {
  max-width: $maxWidth;
  border-top: solid 4px $midnight-black;
  padding: 56px 0 116px;

  @media only screen and (min-width: $sm) {
    display: flex;
    justify-content: center;
    padding-top: 90px;
  }

  @media only screen and (min-width: $md) {
    padding: 190px 0;
  }
}

.cta {
  @media only screen and (min-width: $md) {
    padding: 0;
  }
}

.listingsTableContent {
  width: 100%;
}

.emptyTableContainer {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.4px;
  text-align: center;
  color: #6d6d6d;
  align-items: center;
}

.emptyTableLargeText {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.15px;
}
