$taurus-red: #ff0020;
$taurus-red-bright-20: #de3146;
$taurus-red-20: #c00018;
$taurus-red-40: #9a0013;
$taurus-red-60: #78000f;
$taurus-red-80: #4c000a;
$midnight-black: #272727;
$midnight-rollover: #624f3d;
$midnight-click: #795f46;
$midnight-blackier: #111010;
$midnight-text-rollover: #cbb8a6;
$midnight-text-click: #b89e85;
$sand: #c79769;
$sand-rollover: #b2875e;
$sand-click: #a9815a;
$sand-bright-20: #eec9a5;
$sand-bright-rollover: #c5a07c;
$sand-bright-click: #b48f6c;
$sand-20: #9d7854;
$sand-20-rollover: #9b623b;
$sand-20-click: #995932;
$leather: #994d23;
$leather-rollover: rgba(157, 120, 84, 0.5);
$leather-click: #624f3d;
$metal: #979797;
$metal-bright-50: #c7c7c7;
$metal-alpha-30: rgba(151, 151, 151, 0.3);
$off-white: #eaeaea;
$off-white-material: #fafafa;
$white: #fff;
$fpo: rgba(231, 54, 229, 0.31);
$container-state-off-white-alpha-60: rgba(234, 234, 234, 0.6);
$inactive-grey: #6d6d6d;
$escrow-blue: #01426a;
/* ach-green was originally called escrow-green, but it is only used for the ACH text */
$ach-green: #3cb95d;

:export {
  midnightBlack: $midnight-black;
  midnightBlackier: $midnight-blackier;
  sand: $sand;
  sandBright20: $sand-bright-20;
  sand20: $sand-20;
  leather: $leather;
  metal: $metal;
  metalBright50: $metal-bright-50;
  metalAlpha30: $metal-alpha-30;
  offWhite: $off-white;
  offWhiteMaterial: $off-white-material;
  white: $white;
  inactive-grey: $inactive-grey;
}

$maxWidth: 1150px;

$desktop: 768px;
// mui react-grid breakpoints
$sm: 600px;
$md: 900px;
$lg: 1200px;
$xl: 1536px;

$spacing-xxs: 4px;
$spacing-xs: 8px;
$spacing-s: 12px;
$spacing-m: 16px;
$spacing-l: 20px;
$spacing-xl: 24px;
$spacing-xxl: 28px;
$spacing-xxl-2: 32px;
$spacing-xxl-4: 40px;

@font-face {
  font-family: Roboto-Italic;
  src: local(Roboto-Italic), url('./fonts/Roboto-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: Roboto;
  src: local(Roboto-Regular),
    url('./fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Roboto-Medium;
  src: local(Roboto-Medium), url('./fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Roboto-Bold;
  src: local(Roboto-Bold), url('./fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'ParalucentStencil-Medium';
  src: local(ParalucentStencil-Medium),
    url('./fonts/ParalucentStencil-Medium.woff2') format('woff2'),
    url('./fonts/ParalucentStencil-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Paralucent-Medium';
  src: local(Paralucent-Medium),
    url('./fonts/Paralucent-Medium.woff2') format('woff2'),
    url('./fonts/Paralucent-Medium.woff') format('woff');
}

/* Header mixins */
@mixin Header__H1--Desktop {
  font-family: Roboto;
  font-size: 60px;
  font-weight: bold;
  line-height: 1;
  color: $midnight-black;
}

@mixin Header__H1--Mobile {
  font-family: Roboto;
  font-size: 40px;
  font-weight: bold;
  line-height: 1;
  color: $midnight-black;
}

@mixin Header__H2--Desktop {
  font-family: Roboto;
  font-size: 3.75rem;
  line-height: 1;
  color: $midnight-black;
}

@mixin Header__H3--Desktop {
  font-family: Roboto;
  font-size: 40px;
  line-height: 1;
  color: $midnight-black;
}

@mixin Header__H4-Normal--Desktop {
  font-family: Roboto;
  font-size: 1.75rem;
  line-height: 1.33;
  letter-spacing: 1.46px;
  color: $midnight-black;
}

@mixin Header__H4-Normal--Mobile {
  font-family: Roboto;
  font-size: 1.125rem;
  line-height: 1.33;
  letter-spacing: 0.94px;
  color: $midnight-black;
}

@mixin Header__H4-Bold--Desktop {
  font-family: Roboto;
  font-size: 1.75rem;
  font-weight: bold;
  line-height: 0.82;
  letter-spacing: 1.46px;
  color: $midnight-black;
}

@mixin Header__H4-Bold--Mobile {
  font-family: Roboto;
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 0.94px;
  color: $midnight-black;
}

@mixin Header__H5--Metal--Desktop {
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: $metal;
}

@mixin Header__H6--Desktop {
  font-family: Roboto;
  font-size: 24px;
  line-height: 0.67;
  letter-spacing: 1.25px;
  color: $midnight-black;
}

/* Subtitle mixins */
@mixin Subtitle__Subtitle-1--Sand--Bold--Desktop {
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  line-height: 0.67;
  letter-spacing: 1.25px;
  color: $sand-20;
}

@mixin Subtitle__Subtitle-1--Midnight--Bold--Desktop {
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 1.25px;
  color: $midnight-black;
}

@mixin Subtitle__Subtitle-1--Midnight--Desktop {
  font-family: Roboto;
  font-size: 24px;
  line-height: 1.2;
  letter-spacing: 1.25px;
  color: $midnight-black;
}

@mixin Subtitle__Subtitle-1--Sand--Normal--Desktop {
  font-family: Roboto;
  font-size: 24px;
  letter-spacing: 1.25px;
  color: $sand-20;
}

@mixin Subtitle__Subtitle-1--Metal--Desktop {
  font-family: Roboto;
  font-size: 24px;
  line-height: 0.67;
  letter-spacing: 1.25px;
  color: $metal;
}

@mixin Subtitle__Subtitle-1--Leather--Desktop {
  font-family: Roboto;
  font-size: 24px;
  line-height: 0.67;
  letter-spacing: 1.25px;
  color: $leather;
}

@mixin Subtitle__Subtitle-1--Leather--Mobile {
  font-family: Roboto;
  font-size: 18px;
  line-height: 1.33;
  letter-spacing: 0.94px;
  color: $leather;
}

@mixin Subtitle__Subtitle-1-Sand-Bold--Mobile {
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 0.83px;
  color: $sand-20;
}

@mixin Subtitle__Subtitle-1-Sand-Normal--Mobile {
  font-family: Roboto;
  font-size: 16px;
  line-height: 1;
  color: $sand-20;
}

@mixin Subtitle__Subtitle-2-Midnight--Mobile {
  font-family: Roboto;
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: 0.47px;
  color: $midnight-black;
}

@mixin Subtitle__Subtitle-2--Midnight--Desktop {
  font-family: Roboto;
  font-size: 18px;
  letter-spacing: 0.6px;
  color: $midnight-black;
}

@mixin Subtitle__Subtitle-3-Midnight--Mobile {
  font-family: Roboto;
  font-size: 14px;
  line-height: 1.14;
  color: $midnight-black;
}

@mixin Subtitle__Subtitle-3--midnight--Desktop {
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: 0.63px;
  color: $midnight-black;
}

/* Body mixins */
@mixin Body-1--Desktop {
  font-family: Roboto;
  font-size: 1.125rem;
  line-height: 2.17;
  letter-spacing: 0.56px;
  color: $midnight-black;
}

@mixin Body-1--Mobile {
  font-family: Roboto;
  font-size: 0.875rem;
  line-height: 2;
  letter-spacing: 0.44px;
  color: $midnight-black;
}

@mixin Body__Body-1--Right--Desktop {
  font-family: Roboto;
  font-size: 1.125rem;
  line-height: 2.22;
  letter-spacing: 0.56px;
  text-align: right;
  color: $midnight-black;
}

@mixin Body__Body-1-Left--Mobile {
  font-family: Roboto;
  font-size: 0.875rem;
  line-height: 2;
  letter-spacing: 0.44px;
  color: $midnight-black;
}

@mixin Body__Body-2--Desktop {
  font-family: Roboto;
  font-size: 0.875rem;
  line-height: 1.29;
  letter-spacing: 0.44px;
  color: $midnight-black;
}

@mixin Body__Body-2--Bold--Desktop {
  font-family: Roboto;
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.14;
  letter-spacing: 0.44px;
  color: $midnight-black;
}

@mixin Body__Body-3--Desktop {
  font-family: Roboto;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.38px;
  color: $midnight-black;
}

/* Button & Breadcrumb mixins */
@mixin Button--white--Desktop {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.75px;
  text-align: center;
  color: $white;
}

@mixin Button--Sand--Desktop {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.75px;
  text-align: center;
  color: $sand-20;
}

@mixin Button--Midnight--Desktop {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.75px;
  text-align: center;
  color: $midnight-black;
}

@mixin Button__Icon-Label--Desktop {
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.43px;
  text-align: center;
}

@mixin Button__Link--Sand--Desktop {
  font-family: Roboto;
  font-size: 12px;
  line-height: 1.67;
  letter-spacing: 0.21px;
  color: $sand-bright-20;
}

@mixin Button__Link--Leather--Desktop {
  font-family: Roboto;
  font-size: 12px;
  line-height: 1.67;
  letter-spacing: 0.21px;
  color: $leather;
}

@mixin Breadcrumb__Breadcrumb--Desktop {
  font-family: Roboto;
  font-size: 10px;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 1.67px;
}

@mixin Breadcrumb__Breadcrumb--Active--Desktop {
  color: $midnight-black;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 1.67px;
}

@mixin Breadcrumb__Breadcrumb--Inactive--Desktop {
  font-family: Roboto;
  font-size: 10px;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 1.67px;
  text-align: right;
  color: #6d6d6d;
}

@mixin Breadcrumb__Important--Desktop {
  font-family: Roboto;
  font-size: 10px;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 1.67px;
  color: $leather;
}

/* Specialized mixins */
@mixin Specialized__Table__Left--Bold--Desktop {
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.14;
  letter-spacing: 0.47px;
  color: $midnight-black;
}

@mixin Specialized__Caption--Desktop {
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: italic;
  line-height: 1.57;
  letter-spacing: 0.55px;
  color: $midnight-black;
}

@mixin Specialized__Caption-Metal--Desktop {
  font-family: Roboto;
  font-size: 0.875rem;
  line-height: 1.14;
  letter-spacing: 0.47px;
  color: $metal;
}

@mixin Specialized__UL--White--Desktop {
  font-family: Roboto;
  font-size: 14px;
  line-height: 2.43;
  letter-spacing: 0.35px;
  color: $off-white;
}

@mixin Specialized__Table__Right--Desktop {
  font-family: Roboto;
  font-size: 14px;
  line-height: 1.14;
  letter-spacing: 0.47px;
  text-align: right;
  color: $midnight-black;
}

@mixin Specialized__Table__Left--Regular--Desktop {
  font-family: Roboto;
  font-size: 14px;
  line-height: 1.14;
  letter-spacing: 0.47px;
  color: $midnight-black;
}

@mixin Specialized__Table__Left--Link-Desktop {
  font-family: Roboto;
  font-size: 14px;
  line-height: 1.14;
  letter-spacing: 0.47px;
  color: $leather;
}

@mixin Specialized__Table__Right--Link-Desktop {
  font-family: Roboto;
  font-size: 14px;
  line-height: 1.14;
  letter-spacing: 0.47px;
  text-align: right;
  color: $leather;
}

@mixin Specialized__Filter--Midnight--Desktop {
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0.4px;
  text-align: right;
  color: $midnight-black;
}

@mixin Specialized__Legal {
  font-family: Roboto;
  font-size: 10px;
  font-weight: 500;
  line-height: 1.6;
  text-align: center;
  color: $metal;
}

@mixin Specialized__PDP-price {
  font-family: Roboto;
  font-size: 32px;
  font-weight: bold;
  line-height: 1.25;
  color: $midnight-black;
}

@mixin Specialized__Profile-header {
  font-family: Roboto;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 1.5px;
  color: $sand;
}

@mixin Box-Shadow {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
}

@mixin Dot-Background {
  background-origin: content-box;
  background-clip: content-box;
  background-image: url('../assets/images/dot_background-pattern1.png');
}
