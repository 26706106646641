@use 'style/index.module.scss' as *;

.wrapper {
  position: relative;
  cursor: pointer;
}

.showMenu {
  display: flex;
}

.hideMenu {
  display: none;
}

.wrappedDropdown {
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px $sand;
  border-radius: 16px;
  height: 32px;
  padding: 0 16px;
}

.wrappedLabel {
  @include Specialized__Filter--Midnight--Desktop;
}

.optionsContainer {
  position: absolute;
  top: 8px;
  right: -8px;
  z-index: 1;
  flex-direction: column;
  width: max-content;
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14);

  @media only screen and (min-width: $sm) {
    top: 10px;
  }
}

.option {
  @include Body__Body-2--Desktop;
  height: 40px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  text-decoration: none;

  &:hover {
    background-color: $midnight-text-rollover;
  }

  &:focus {
    background-color: $midnight-text-click;
  }
}

.firstOption {
  border-radius: 8px 8px 0 0;
}

.lastOption {
  border-radius: 0 0 8px 8px;
}

.firstOption.lastOption {
  border-radius: 8px;
}

.option.disabledOption {
  color: $metal;
  cursor: not-allowed;
  background-color: $off-white-material;
}
