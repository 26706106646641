@use 'style/index.module.scss' as *;

.maxWidth {
  @media only screen and (min-width: $md) {
    max-width: $maxWidth;
    margin: auto;
  }
}

.headerContainer {
  justify-content: center;
  background-color: $off-white;
  padding: 20px 8px 45px;

  @media only screen and (min-width: $md) {
    padding: 24px 0 60px;
  }
}

.title {
  @include Header__H1--Mobile;
  text-transform: capitalize;
}

.shoppingContainer {
  padding: 0 8px;

  @media only screen and (min-width: $sm) {
    margin: 0 16px;
  }

  @media only screen and (min-width: $md) {
    max-width: $maxWidth;
    margin: auto;
    padding: 40px 0 0;
  }
}

.filterButtonContainer {
  margin: 0 auto 24px;

  @media only screen and (min-width: $sm) {
    margin-bottom: 40px;
  }

  @media only screen and (min-width: $md) {
    margin-bottom: 24px;
  }
}

.filteredButton {
  width: 100%;
}

.sideMenu {
  min-width: min-content;
}

.rightSideComponents {
  @media only screen and (min-width: $sm) {
    margin: auto;
  }

  @media only screen and (min-width: $md) {
    margin: unset;
    flex-direction: column;
    flex-wrap: nowrap;
  }
}

.badgeContainer {
  height: min-content;
  margin: 0 8px 24px;

  @media only screen and (min-width: $sm) {
    margin: 0 0 40px;
  }

  @media only screen and (min-width: $md) {
    margin: 0 0 24px;
  }
}

.emptyHeader {
  height: 116px;
}

.noFiltersOrKeywords {
  @include Subtitle__Subtitle-1--Midnight--Desktop;
  line-height: 1.5;
  margin-top: 10px;
}
