@use 'style/index.module.scss' as *;

.modal::-webkit-scrollbar-thumb {
  background-color: $metal;
  border: 4px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;
}

.modal::-webkit-scrollbar {
  width: 16px;
  background-color: transparent;
}

.modalContentWrapper {
  height: 100%;
}

.modalButtonContainer,
.header {
  background-color: $taurus-red-80;
}

.header {
  @include Header__H1--Mobile;
  line-height: 1.2;
  color: $white;
  padding: 0 12px 25px;
}

.contentWrapper {
  margin: 16px 8px;
  padding: 16px 8px;
  background-color: $white;
}

.subheaderText {
  @include Subtitle__Subtitle-2-Midnight--Mobile;
  margin: 0 8px 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (min-width: $sm) {
    @include Subtitle__Subtitle-2--Midnight--Desktop;
  }
}

.buttonContainer {
  padding: 25px 0;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}

.rejectButton {
  width: 100%;
}

.cancelButtonWrapper {
  text-align: center;
  margin-top: 30px;
}

.cancelButton {
  width: fit-content;
}

.contactUsWrapper {
  margin: 40px 0;
  text-align: center;
}

.contactUsLink {
  width: fit-content;
  height: 56px;
}
