@use 'style/index.module.scss' as *;

.wrapper {
  border-top: solid 1px $metal;
  padding-top: 15px;
  margin-top: 40px;

  @media only screen and (min-width: $sm) {
    flex-direction: row;
    padding-top: 0;
    margin-top: 100px;
    border-top: none;
  }
}

.imageAvatar {
  border: solid 1px $white;
  width: 56px;
  height: 56px;

  @media only screen and (min-width: $sm) {
    width: 80px;
    height: 80px;
    margin: unset;
  }
}

.imageContainer {
  @media only screen and (min-width: $sm) {
    padding-top: 48px;
    margin-right: 16px;
    min-width: fit-content;
  }

  @media only screen and (min-width: $md) {
    margin-right: 0;
  }
}

.image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  text-align: center;
}

.rightOfImage {
  @media only screen and (min-width: $sm) {
    border-top: solid 1px $metal;
    padding-top: 48px;
    justify-content: space-between;
  }
}

.infoContainer {
  margin-bottom: 22px;

  @media only screen and (min-width: $sm) {
    justify-content: flex-end;
  }
}

.soldBy {
  @include Header__H4-Normal--Mobile;

  @media only screen and (min-width: $sm) {
    @include Header__H4-Normal--Desktop;
  }
}

.userName {
  @include Header__H4-Bold--Mobile;

  @media only screen and (min-width: $sm) {
    @include Header__H4-Bold--Desktop;
  }
}

.addressAndMember {
  flex-direction: column;

  @media only screen and (min-width: $sm) {
    flex-direction: row;
    margin: 8px 0;
  }
}

.address {
  @include Body__Body-2--Bold--Desktop;
}

.memberSince {
  @include Body__Body-2--Desktop;

  @media only screen and (min-width: $sm) {
    margin-left: 8px;
  }
}

.ordersAndRating {
  margin-top: 8px;
}

.userRating {
  @include Body__Body-2--Bold--Desktop;
  display: flex;
}

.sixteenStar {
  width: 16px;
  height: 16px;
}

.ratingsCount {
  @include Body__Body-2--Desktop;
  color: $metal;
}

.buttonContainer {
  justify-content: center;

  @media only screen and (min-width: $sm) {
    justify-content: flex-end;
    align-items: flex-start;
  }
}

.viewSellerTerms {
  margin: 8px 0 40px;
  width: 100%;

  @media only screen and (min-width: $sm) {
    margin: 0;
  }
}

.buttonColor {
  background-color: transparent;
}
