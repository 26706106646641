@use 'style/index.module.scss' as *;

.container {
  flex-wrap: nowrap;
  padding: 12px 8px 24px;

  @media screen and (min-width: $md) {
    padding: 40px 0px;
    margin-bottom: 0;
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: $maxWidth;
  width: 100%;

  @media screen and (max-width: $md) {
    flex-wrap: wrap;
  }
}

.sell {
  background-color: $midnight-black;
}

.logoWrapper {
  align-items: center;
  display: flex;
  padding-bottom: 16px;
  width: 155px;
  height: 45px;

  @media screen and (min-width: $md) {
    padding-bottom: 0;
    min-width: 175px;
    height: 50px;
  }
}

.logo {
  width: 100%;
  height: 100%;
}

.searchInput {
  @media screen and (min-width: $md) {
    margin-left: 16px;
  }
}
