@use 'style/index.module.scss' as *;

.subHeading {
  @include Body__Body-2--Bold--Desktop;
  margin-bottom: 8px;
}

.buttonContainer {
  margin-top: 30px;
}
