@use 'style/index.module.scss' as *;

.question {
  cursor: pointer;
  height: 48px;
  border-bottom: 1px solid $off-white;
  align-items: center;
}

.questionLink {
  padding-left: 0;
}

.buttonContainer {
  margin-top: 30px;
}
