@use 'style/index.module.scss' as *;

.shippingPrice {
  @include Subtitle__Subtitle-2--Midnight--Desktop;
}

.boldShipPrice,
.boldPickupPrice {
  font-weight: bold;
}

.formWrapper {
  padding-bottom: 0;
}

.formBody {
  padding: 0;
}

.formTitle {
  margin-bottom: 0;
}

.radioButtonWrapper,
.detailsWrapper {
  padding: 0 8px;
}

.detailsWrapper {
  background-color: $off-white-material;
  padding-bottom: 24px;
  border-radius: 0 0 8px 8px;
}

.detailsHeader {
  @include Body__Body-2--Desktop;
  font-weight: bold;
  margin: 15px 0 8px;
}

.detailsInfo,
.detail {
  @include Body__Body-2--Desktop;
}

.details {
  margin-top: 24px;
}

.detail {
  display: flex;
  align-items: center;
  height: 40px;
}
