@use 'style/index.module.scss' as *;

.wrapper {
  padding: 0 8px;

  @media only screen and (min-width: $md) {
    @include Dot-Background;
    border: solid 1px $metal-alpha-30;
    padding: 16px;
  }
}

.imageBackground {
  @include Dot-Background;
  height: 24px;

  @media only screen and (min-width: $md) {
    display: none;
  }
}

.inputButtonContainer {
  display: flex;
  position: relative;
  height: 48px;
  margin: 8px 0 16px;

  @media only screen and (min-width: $md) {
    margin: 28px auto;
  }
}

.filterButton {
  position: absolute;
  left: 3px;
  top: 8px;
  height: 32px;
  width: 100px;

  .button {
    @include Button__Icon-Label--Desktop;
    color: $white;
    font-weight: normal;
    padding: 0 8px;
  }
}

.searchInput {
  height: 100%;
  flex: 1;
}

.input {
  padding-left: 111px;
  background-color: $white;
  border-radius: 0;

  @media only screen and (min-width: $md) {
    padding-left: 8px;
  }
}
