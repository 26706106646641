@use 'style/index.module.scss' as *;

.input {
  margin-bottom: 48px;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

.actionButton {
  width: 200px;
  height: 48px;
}

.cancelButton {
  @media only screen and (min-width: $md) {
    width: 150px;
  }
}
