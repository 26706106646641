@use 'style/index.module.scss' as *;

.boldText {
  font-weight: bold;
}

.addressFormat {
  margin: 15px 0 15px 50px;
  line-height: 1.2;
}

.sectionHeader {
  text-align: center;
  font-weight: bold;
  text-decoration: underline;
}

.indent {
  text-indent: 90px;
}

.link {
  @include Body-1--Mobile;
  padding: 0;
  margin: 0;

  @media only screen and (min-width: $md) {
    @include Body-1--Desktop;
  }
}
