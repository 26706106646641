@use 'style/index.module.scss' as *;

.wrapper {
  padding-bottom: 24px;

  @media only screen and (min-width: $sm) {
    padding: 0 6px 24px;
  }
}

.cardContainer {
  width: 100%;
  border: solid 1px $off-white;
  background-color: $off-white-material;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.14);

  &:hover {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14);
  }

  @media only screen and (min-width: $sm) {
    max-width: 368px;
  }

  @media only screen and (min-width: $md) {
    box-shadow: none;
    min-width: 368px;
    max-width: 390px;
  }
}

.topInfo {
  height: calc(100% - 57px);
}

.leftSide {
  width: 176px;
  height: 152px;
}

.image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.rightSide {
  padding: 0 5px;
  background-color: $off-white-material;
}

.subcategory {
  @include Specialized__Filter--Midnight--Desktop;
  color: $metal;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 24px;
  line-height: 24px;
  width: inherit;
  margin-bottom: 8px;
}

.name {
  @include Subtitle__Subtitle-3--midnight--Desktop;
  line-height: 16px;
  max-height: 32px;
  width: 80%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.price {
  @include Subtitle__Subtitle-1--Leather--Desktop;
  color: $sand-20;
  font-weight: bold;
  margin: 8px 0;
  height: 32px;
  display: flex;
  align-items: center;
}

.sellerRatingContainer {
  display: flex;
  align-items: center;
  height: 16px;
}

.seller {
  @include Body__Body-2--Desktop;
  margin-right: 4px;
  color: $metal;
}

.sellerRating {
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.47px;
  color: $midnight-black;
  margin-right: 2px;
}

.starIcon {
  width: 18px;
  height: 18px;
}

.condition {
  @include Body__Body-2--Desktop;
  height: 24px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}

.bottomButtons {
  height: 57px;
  overflow: hidden;
}

.lineSeparator {
  width: 100%;
  height: 1px;
  opacity: 0.2;
  border-top: solid 1px $metal;
}

.button {
  border-right: solid 1px $off-white;
}

.noImageBackground {
  @include Subtitle__Subtitle-3-Midnight--Mobile;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 176px;
  height: 152px;
}

.soldBadge {
  @include Subtitle__Subtitle-3--midnight--Desktop;
  width: 66px;
  height: 30px;
  border-radius: 15px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.14);
  background-color: $white;
  position: absolute;
  top: 61px;
  left: 56px;
}

.soldOut {
  .leftSide {
    border: solid 4px $metal;
    background-color: rgba(234, 234, 234, 0.7);
    position: relative;
  }

  .image {
    mix-blend-mode: luminosity;
    opacity: 0.3;
  }

  .name,
  .price,
  .sellerRating,
  .condition {
    color: $metal;
  }

  .price {
    text-decoration: line-through;
  }
}
