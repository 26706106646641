@use 'style/index.module.scss' as *;

.previousStepContainer {
  padding: 0 16px;
  border-radius: 8px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.14);
  background-color: $off-white-material;
  display: flex;
  flex-direction: column;
  height: fit-content;
  margin-top: 14px;

  @media only screen and (min-width: $sm) {
    margin-top: 30px;
  }

  @media only screen and (min-width: $md) {
    margin-left: 16px;
    margin-top: 0;
  }
}

.stepContainer {
  display: flex;
  justify-content: space-between;
}

.lineSeperation {
  border-top: solid 1px $metal;
}

.stepInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 0 12px;
}

.sectionName {
  @include Breadcrumb__Breadcrumb--Inactive--Desktop;
}

.stepHeader {
  @include Specialized__Table__Left--Regular--Desktop;
  margin: 6px 0 2px;
}

.stepSubHeader {
  @include Specialized__Filter--Midnight--Desktop;
  color: $metal;
  text-align: left;
}

.changeButton {
  padding-right: 0;
}
