@use 'style/index.module.scss' as *;

.headerContainer {
  background-color: $off-white;
  padding: 30px 8px;

  @media only screen and (min-width: $sm) {
    padding: 30px 16px 45px;
  }

  @media only screen and (min-width: $md) {
    padding: 30px 0 64px;
    justify-content: center;
  }
}

.header {
  @include Header__H1--Mobile;
  margin-bottom: 24px;

  @media only screen and (min-width: $md) {
    @include Header__H1--Desktop;
    margin-bottom: 100px;
  }
}

.menuWrapper {
  display: flex;
  justify-content: flex-end;
}

.headerPreviousSteps {
  display: block;

  @media only screen and (min-width: $sm) {
    margin: auto;
  }

  @media only screen and (min-width: $md) {
    display: none;
  }
}

.desktopPreviousSteps {
  display: none;

  @media only screen and (min-width: $md) {
    display: block;
  }
}

.bodyContainer {
  min-height: 580px;
  padding: 32px 8px 0;
  background-color: $off-white-material;
  margin-bottom: 100px;

  @media only screen and (min-width: $md) {
    padding: 32px 0 0;
  }
}

.formSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.bottomButtonsContainer {
  border-top: 4px solid $midnight-black;
  text-align: center;
  padding: 38px;
  margin-top: 80px;
}

.continueButton {
  width: 100%;
}

.contentWrapper {
  width: inherit;
  // min-width: fit-content;
}

.stepTracker {
  @media only screen and (min-width: $desktop) {
    margin: auto;
  }
}
