@use 'style/index.module.scss' as *;

.container {
  width: 100%;
  max-width: 464px;

  @media only screen and (min-width: $sm) {
    top: 390px;
    width: 100%;
  }
}

.actionContainer {
  max-width: 464px;
  margin: 0 8px;
  border-radius: 8px;
  background-color: $white;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14);

  @media only screen and (min-width: $md) {
    margin: 0;
  }
}

.actionHeader {
  padding: 16px 16px 8px;
  background-color: $off-white-material;
  border-radius: 8px 8px 0 0;
}

.divider {
  opacity: 0.2;
  background-color: $metal;
  height: 1px;
  width: 100%;
}

.price {
  @include Specialized__PDP-price;
}

.amountForPrice {
  @include Subtitle__Subtitle-3-Midnight--Mobile;
  display: flex;
}

.for {
  font-style: italic;
}

.shippingAdded {
  @include Body__Body-2--Desktop;
  color: $metal;
}

.quantityInLot {
  @include Body-1--Desktop;
  line-height: 1;
  margin-top: 20px;
}

.quantityDropdownWrapper {
  padding: 16px 16px 0;

  @media only screen and (min-width: $sm) {
    padding: 0 8px 0 0;
  }
}

.lotActionButtons {
  @media only screen and (min-width: $sm) {
    padding: 16px;
  }

  .actionButtons {
    padding: 16px;

    @media only screen and (min-width: $sm) {
      padding: 0;
    }
  }
}

.perUnitActionButtons {
  @media only screen and (min-width: $sm) {
    padding: 16px 16px 4px;
  }

  .actionButtons {
    padding: 16px 16px 4px;

    @media only screen and (min-width: $sm) {
      padding: 0;
    }
  }
}

.actionButtons {
  align-items: center;
}

.availableAmount {
  @include Body__Body-3--Desktop;
  font-style: italic;
  margin-left: 16px;
  padding-bottom: 6px;
}
