@use 'style/index.module.scss' as *;

.modal {
  background-color: $off-white-material;
  border-radius: 4px;
  height: fit-content;
}

.headerWrapper {
  @include Header__H1--Mobile;
  background-color: $off-white;
  margin-bottom: 20px;
  padding: 16px 8px;
}

.logo {
  margin-bottom: 16px;
}

.bodyText {
  @include Body-1--Desktop;
  line-height: 1.5;
}

.buttonContainer {
  width: 100%;
}

.rejectButton {
  background-color: transparent;
}
